import Box from "@amzn/awsui-components-react/polaris/box"
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout"
import Container from "@amzn/awsui-components-react/polaris/container"
import Header from "@amzn/awsui-components-react/polaris/header"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import { useFormikContext } from "formik"

import { BUILDER_ACTIVITY_RECORD } from "../../models/builder"
import { buildComments } from "./WizardContent"

export interface ReviewStepProps {
  title: string
  activityDate: string
  activity: string
  comments: string
  linked: string
  relatedId: string
  relatedType: string
  subActivity: string
  record: BUILDER_ACTIVITY_RECORD
  participants: string
  url: string
  csat: string
  timeSpentHrs: string
  status: string
}

export function ReviewStep() {
  const { values } = useFormikContext<ReviewStepProps>()

  const getFiscalYear = (closeDateString: string) =>
    closeDateString && !Number.isNaN(closeDateString)
      ? parseInt(
          new Date(closeDateString).getFullYear().toString().substring(2)
        )
      : parseInt(new Date().getFullYear().toString().substring(2))

  const getFiscalYearString = (closeDateString: string) =>
    `FY${getFiscalYear(closeDateString)}`

  const getTitle = () => {
    return [
      getFiscalYearString(values.activityDate),
      "PTNR",
      `${values.record.outcome.type}`,
      values.title,
    ].join("-")
  }

  return (
    <SpaceBetween size="xs">
      <Header variant="h3"></Header>
      <Container header={<Header variant="h2">{getTitle()}</Header>}>
        <ColumnLayout columns={2} variant="text-grid">
          <div>
            <Box margin={{ bottom: "xxxs" }} color="text-label">
              Activity
            </Box>
            <div>{values.activity}</div>
          </div>
          <div>
            <Box margin={{ bottom: "xxxs" }} color="text-label">
              Sub-Activity
            </Box>
            <div>{values.subActivity}</div>
          </div>
          <div>
            <Box margin={{ bottom: "xxxs" }} color="text-label">
              SFDC Related Type
            </Box>
            <div>{values.relatedType}</div>
          </div>
          <div>
            <Box margin={{ bottom: "xxxs" }} color="text-label">
              SFDC Related ID
            </Box>
            <div>{values.relatedId}</div>
          </div>
          <div>
            <Box margin={{ bottom: "xxxs" }} color="text-label">
              Status
            </Box>
            <div>{values.status}</div>
          </div>
          <div>
            <Box margin={{ bottom: "xxxs" }} color="text-label">
              Time Spent (Hrs)
            </Box>
            <div>{values.timeSpentHrs}</div>
          </div>
          <div>
            <Box margin={{ bottom: "xxxs" }} color="text-label">
              Comments
            </Box>
            <a>{buildComments(values)}</a>
          </div>
          <div>
            <Box margin={{ bottom: "xxxs" }} color="text-label">
              Activity Date
            </Box>
            <div>{values.activityDate}</div>
          </div>
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  )
}
