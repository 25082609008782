import { Task } from "models/task"

export type TaskRequest = Omit<
  Task,
  | "lastModifiedDate"
  | "id"
  | "accountName"
  | "createdDate"
  | "opportunityName"
  | "subject"
>

export type TaskUpdateRequest = Omit<Task, "lastModifiedDate" | "id">

import request from "pmsa-polaris/api/request"

export type TaskResponse = Readonly<Task>

const tasksApi = {
  getAll: (alias?: string) =>
    request.get<TaskResponse[]>("/tasks", { params: { alias } }),

  get: (id: string) => request.get<TaskResponse>(`/tasks/${id}`),

  create: (params: TaskRequest) => request.post<TaskResponse>("/tasks", params),

  update: (id: string, params: TaskRequest) =>
    request.put<TaskResponse>(`/tasks/${id}`, params),
}

export default tasksApi
