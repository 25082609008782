import {
  Button,
  ColumnLayout,
  Container,
  Form,
  FormField,
  Header,
  Link,
  SpaceBetween,
  TextContent,
} from "@amzn/awsui-components-react/polaris"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events"
import opportunities, { OpportunityRequest } from "api/opportunities"
import tasksApi, { TaskRequest } from "api/tasks"
import { Analytics } from "aws-amplify"
import { AxiosError } from "axios"
import GeoRegionSelector from "components/GeoRegionSelector"
import PartnerFormField from "components/PartnerFormField"
import { useWorkstreamCatalog } from "context/WorkstreamCatalogContext"
import { Formik, FormikHelpers, FormikProps } from "formik"
import { OPPORTUNITY_STAGE } from "models/opportunity"
import { OmitWorkstreamId } from "models/shared"
import { WorkstreamItem } from "models/workstream"
import moment from "moment"
import FieldContent from "pages/CreateUpdateTask/FieldContent"
import {
  DEFAULT_TASK,
  getValidaionSchema as getTaskValidationSchema,
} from "pages/CreateUpdateTask/FormContent"
import ErrorFocus from "pmsa-polaris/components/ErrorFocus"
import Fieldset from "pmsa-polaris/components/Fieldset"
import {
  DatePickerFormField,
  InputFormField,
  SelectFormField,
  TextAreaFormField,
} from "pmsa-polaris/components/FormFields"
import LoadingHeader from "pmsa-polaris/components/LoadingHeader"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useFlashbar, { FlashMessage } from "pmsa-polaris/hooks/useFlashbar"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { toOptions } from "pmsa-polaris/utils"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import routes, { routeParams } from "routes"
import * as Yup from "yup"

import topics from "./tools-topics"

const { sfdcBaseUrl } = config

type TaskFormValues = Omit<OmitWorkstreamId<TaskRequest>, "milestone"> & {
  milestoneId: string
}

export type OpportunityFormValues = OmitWorkstreamId<OpportunityRequest> & {
  accountName: string
}

type OpportunityTaskFormValues = {
  opportunity: OpportunityFormValues
  tasks: TaskFormValues[]
}

const DEFAULT_FORM_VALUES = {
  opportunity: {
    title: "",
    closeDate: moment().add(3, "months").format("YYYY-MM-DD"),
    nextStep: "",
    description: "",
    workstreamName: "",
    accountName: "",
    accountId: "",
    stageName: OPPORTUNITY_STAGE[0],
    owner: "",
    solution: "",
  },
  tasks: [],
}

const WORKSTREAM_TYPE = {
  competency: "Partner Competency",
  ambassador: "APN Ambassador Program",
} as const

const stageOptions = toOptions(...OPPORTUNITY_STAGE)

const getValidationSchema = () => {
  return Yup.object().shape({
    opportunity: Yup.object().shape({
      workstreamName: Yup.string().required(
        "You must specify opportunity type."
      ),
      solution: Yup.string().required("You must specify a solution."),
      accountId: Yup.string().required(
        "You must specify a partner name or spms id."
      ),
      title: Yup.string()
        .required("You must specify title.")
        .max(90, "Opportunity name can't be more than 90 characters.")
        .matches(/^[^\\]*$/, "Backslash is not allowed."),
      nextStep: Yup.string()
        .max(255, "Next step can't be more than 255 characters.")
        .matches(/^[^\\]*$/, "Backslash is not allowed."),
      description: Yup.string()
        .max(32000, "Description can't be more than 32000 characters.")
        .required("You must specify description.")
        .matches(/^[^\\]*$/, "Backslash is not allowed."),
      closeDate: Yup.date()
        .required("You must specify close date.")
        .typeError("You must specify date in the format YYYY/MM/DD")
        .when("stageName", {
          is: (val: string) => val === "Completed" || val === "Closed Lost",
          then: (schema) =>
            schema
              .min(
                "2020/01/01",
                "You must specify date greater than 2020/01/01"
              )
              .max(
                moment().add(60, "days"),
                "You must specify date within 60 days from today"
              ),
          otherwise: (schema) =>
            schema.min(
              moment(),
              "Close date must be set to future date if Stage is not Completed or Closed Lost."
            ),
        }),

      stageName: Yup.string().required("You must specify stage."),
    }),
    tasks: Yup.array().of(getTaskValidationSchema(false)),
  })
}
const FormContent: React.FC = () => {
  const { id } = useParams<{
    id?: string
  }>()

  const [searchParams] = useSearchParams()
  const searchObject = Object.fromEntries(searchParams)

  const [initialValues, setInitialValues] = useState<OpportunityTaskFormValues>(
    {
      tasks: DEFAULT_FORM_VALUES.tasks,
      opportunity: {
        ...DEFAULT_FORM_VALUES.opportunity,
        ...searchObject,
      },
    }
  )

  const { accountId: initialAccountId, accountName: initialAccountName } =
    initialValues.opportunity

  const [initialPartnerOptions, setInitialPartnerOptions] = useState<
    OptionDefinition[]
  >(
    (initialAccountId &&
      initialAccountName && [
        {
          label: initialAccountName,
          value: initialAccountId,
        },
      ]) ||
      []
  )

  const navigate = useNavigate()
  const { setContext } = useAppContext()

  const { workstreamOptions, workstreamsLoading, streamsByName } =
    useWorkstreamCatalog()

  const [
    { loading: createOpporunityLoading, error: createOpporunityError },
    createOpportunity,
  ] = usePromise(opportunities.create)

  const [
    {
      loading: updateOpporunityLoading,
      data: updatedOpportunity,
      error: updateOpporunityError,
    },
    updateOpportunity,
  ] = usePromise(opportunities.update)

  const [
    {
      loading: getOpporunityLoading,
      data: existingOpportunity,
      error: getOpporunityError,
    },
    getOpportunity,
  ] = usePromise(opportunities.get)

  const [
    { loading: createTaskLoading, data: createdTask, error: createTaskError },
    createTask,
  ] = usePromise(tasksApi.create)

  const isLoading =
    createOpporunityLoading ||
    updateOpporunityLoading ||
    getOpporunityLoading ||
    createTaskLoading ||
    workstreamsLoading

  const setFlashMessages = useFlashbar()

  const handleInfoClicked =
    (toolsTopic: keyof typeof topics) =>
    (e: CustomEvent<BaseNavigationDetail>) => {
      e.preventDefault()
      setContext({ toolsOpen: true, toolsTopic })
    }

  useEffect(() => {
    if (id) {
      const fetchOpportunity = async () => {
        const opp = await getOpportunity(id)
        const values: OpportunityTaskFormValues = {
          opportunity: {
            ...opp,
            title: opp.title ?? "",
            nextStep: opp.nextStep ?? "",
            description: opp.description ?? "",
            workstreamName: opp.workstreamName ?? "",
            solution: opp.solution ?? "",
          },
          tasks: [],
        }
        setInitialPartnerOptions([
          {
            label: opp.accountName,
            value: opp.accountId,
          },
        ])
        setInitialValues(values)
      }
      fetchOpportunity()
    }
  }, [getOpportunity, id])

  const handleSubmit = useCallback(
    async (
      {
        opportunity: { workstreamName, ...opportunity },
        tasks,
      }: OpportunityTaskFormValues,
      { setSubmitting }: FormikHelpers<OpportunityTaskFormValues>
    ) => {
      try {
        if (opportunity) {
          const parsedWorkstreamId =
            streamsByName &&
            streamsByName.workstreams[workstreamName] &&
            streamsByName.workstreams[workstreamName].id.split(">")[1]
              ? streamsByName.workstreams[workstreamName].id.split(">")[0]
              : streamsByName?.workstreams[workstreamName].id

          const opportunityRequest: OpportunityRequest = {
            ...opportunity,
            workstreamId: parsedWorkstreamId ?? "",
          }

          if (id) {
            const updatedOpportunity = await updateOpportunity(
              id,
              opportunityRequest
            )
            setFlashMessages([
              {
                content: "Opportunity updated successfully",
                presist: true,
                type: "success",
              },
            ])

            navigate(routeParams.opportunitiesDetails({ id }))
          } else {
            const newOpportunity = await createOpportunity(opportunityRequest)

            const flashErrors: FlashMessage[] = []

            await Promise.all(
              tasks.map(async ({ workstreamName, ...task }, index) => {
                const taskWorkstream = streamsByName?.tasks[workstreamName]
                const workstreamMilestone = taskWorkstream?.milestones?.find(
                  (milestone) => milestone.id === task.milestoneId
                )!

                const taskRequest: TaskRequest = {
                  ...task,
                  workstreamId: taskWorkstream?.id ?? "",
                  relatedId: newOpportunity.id,
                }
                try {
                  await tasksApi.create(taskRequest)
                } catch (error) {
                  console.error(error)
                  flashErrors.push({
                    content: "There was an issue with creating task.",
                    presist: true,
                    type: "error",
                  })
                }
              })
            )

            setFlashMessages([
              {
                presist: true,
                type: "success",
                content: "Opportunity created successfully",
              },
              ...flashErrors,
            ])
            navigate(
              routeParams.opportunitiesDetails({ id: newOpportunity.id })
            )
          }
        }
      } catch (error) {
        const err = error as AxiosError<{ statusCode: number; message: string }>
        if (
          err.response &&
          err.response.data &&
          err.response.data.statusCode == 403 &&
          err.response.data.message == "user does not own record"
        ) {
          sfdcBaseUrl

          const errmsg = `Only records that you own can be updated.  If you need to change the opportunity owner, navigate to ${sfdcBaseUrl}/${id}`
          setFlashMessages([
            {
              type: "error",
              content: errmsg,
            },
          ])
        } else {
          setFlashMessages([
            {
              type: "error",
              content: "There was an issue with creating opportunity.",
            },
          ])
        }
      } finally {
        setSubmitting(false)
        const event = Analytics.record({ name: "submitted opportunity" })
      }
    },
    [
      streamsByName,
      id,
      updateOpportunity,
      setFlashMessages,
      navigate,
      createOpportunity,
    ]
  )

  const [currentWorkstream, setCurrentWorkstream] = useState("")

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const pageTitle = id ? "Update Opportunity" : "Create Opportunity"

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        errors,
        values: { tasks, opportunity },
        isSubmitting,
        setFieldValue,
      }: FormikProps<OpportunityTaskFormValues>) => {
        const handleAddTaskClick = () => {
          setFieldValue("tasks", [
            ...tasks,
            { ...DEFAULT_TASK, relatedId: "n/a" },
          ])
        }

        const handleTaskDelete = (index: number) => () => {
          const newTasks = [...tasks]
          newTasks.splice(index, 1)

          setFieldValue("tasks", newTasks)
        }

        const selectedOpportunityWorkstream: WorkstreamItem | undefined =
          streamsByName?.workstreams[opportunity.workstreamName]

        const titleOptions = streamsByName?.workstreams[
          opportunity.workstreamName
        ]?.titleOptions?.map((v) => ({
          value: v,
        }))

        const getFiscalYear = (closeDateString: string) =>
          parseInt(
            new Date(closeDateString).getFullYear().toString().substring(2)
          )

        const getFiscalYearString = (closeDateString: string) =>
          `FY${getFiscalYear(closeDateString)}`

        const existingTitle =
          streamsByName && opportunity.workstreamName
            ? streamsByName.workstreams[opportunity.workstreamName].id.split(
                ">"
              )[1]
            : undefined

        opportunity.title = existingTitle || opportunity.title

        const opportunityName =
          (getOpporunityLoading && "-") ||
          [
            streamsByName && opportunity.workstreamName
              ? streamsByName.workstreams[opportunity.workstreamName].id
              : "[Opportunity Type]",
            opportunity.accountId ? opportunity.accountId : "[Partner]",
            opportunity.solution ? opportunity.solution : "[Solution]",
            opportunity.title ? opportunity.title : "[Detail]",
          ].join("-")

        const workstreamChanged = (e: any) => {
          setCurrentWorkstream(e.detail.selectedOption.value)
        }

        return (
          <form
            onSubmit={handleSubmit}
            className={isLoading ? "loading" : undefined}
          >
            <Form
              header={
                <LoadingHeader loading={isLoading}>{pageTitle}</LoadingHeader>
              }
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <NavigationButton
                    variant="link"
                    href={
                      (id && routeParams.opportunitiesDetails({ id })) ||
                      routes.home
                    }
                    disabled={isSubmitting || isLoading}
                  >
                    Cancel
                  </NavigationButton>
                  <Button
                    variant="primary"
                    formAction="submit"
                    disabled={isSubmitting || isLoading}
                    loading={isSubmitting || isLoading}
                  >
                    {pageTitle}
                  </Button>
                </SpaceBetween>
              }
              errorText={
                updateOpporunityError ||
                createOpporunityError ||
                getOpporunityError
                  ? "We can't process the request right now because of an issue with the server. Try again later.  If the problem persists, contact support"
                  : ""
              }
            >
              <ErrorFocus />
              <SpaceBetween size="l">
                <Container
                  header={
                    <Header
                      info={
                        <Link
                          variant="info"
                          onFollow={handleInfoClicked("main")}
                        >
                          Info
                        </Link>
                      }
                    >
                      Opportunity (Outcome)
                    </Header>
                  }
                >
                  <SpaceBetween size="l">
                    <FormField
                      label="Opportunity Name"
                      description="Formatted opportunity name based selections"
                    >
                      <TextContent>
                        <strong>{opportunityName}</strong>
                      </TextContent>
                    </FormField>

                    <SelectFormField
                      name="opportunity.workstreamName"
                      label="Opportunity Type"
                      filteringType="auto"
                      options={workstreamOptions}
                      onChange={workstreamChanged}
                      required
                      info={
                        <Link
                          variant="info"
                          onFollow={handleInfoClicked("helpOpportunityType")}
                        >
                          Info
                        </Link>
                      }
                      description="Choose the appropriate Workstream from the Catalog"
                    />

                    <PartnerFormField
                      label="Account Name (Partner)"
                      initialOptions={initialPartnerOptions}
                      name="opportunity.accountId"
                      description="Enter the Partner Name associated with the workstream or enter their SPMS ID."
                      required
                    />

                    <InputFormField
                      key="solution"
                      name="opportunity.solution"
                      inputMode="text"
                      label="Solution"
                      description="Enter the solution or product of the opportunity."
                      required
                    />

                    <InputFormField
                      key="title"
                      name="opportunity.title"
                      inputMode="text"
                      label="Detail"
                      description="Enter the detail of the opportunity."
                      required
                    />

                    <ColumnLayout columns={3}>
                      <DatePickerFormField
                        label="Close Date"
                        openCalendarAriaLabel={(selectedDate) =>
                          "Choose Date" +
                          (selectedDate
                            ? `, selected date is ${selectedDate}`
                            : "")
                        }
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                        name="opportunity.closeDate"
                        description="The tentative date of when the opportunity will be closed."
                        required
                      />

                      <SelectFormField
                        label="Stage"
                        name="opportunity.stageName"
                        options={stageOptions}
                        description="Adjust the stage through opportunity progression.  Must be Completed or Closed Lost with description when close date is reached."
                        required
                      />
                    </ColumnLayout>

                    <TextAreaFormField
                      label="Description"
                      stretch
                      name="opportunity.description"
                      description="Describe details of the outcome intended for this opportunity."
                      required
                    />
                  </SpaceBetween>
                </Container>

                {!id && (
                  <Container header={<Header>Task (Activity)</Header>}>
                    <SpaceBetween size="l">
                      {tasks.map((_, index) => (
                        <Fieldset
                          legend={`Task ${index + 1}`}
                          key={index.toString()}
                        >
                          <FieldContent
                            opportunityData={opportunity}
                            namePrefix={`tasks[${index}]`}
                            hideRelatedPicker
                            onInfoClicked={handleInfoClicked}
                          >
                            <Button
                              onClick={handleTaskDelete(index)}
                              disabled={isSubmitting || isLoading}
                            >
                              Delete
                            </Button>
                          </FieldContent>
                        </Fieldset>
                      ))}

                      <Button
                        disabled={tasks.length > 2 || isSubmitting || isLoading}
                        onClick={handleAddTaskClick}
                        formAction="none"
                      >
                        Add Task
                      </Button>
                    </SpaceBetween>
                  </Container>
                )}
              </SpaceBetween>
            </Form>
          </form>
        )
      }}
    </Formik>
  )
}

export default FormContent
