import { useCollection } from "@amzn/awsui-collection-hooks"
import {
  Container,
  Pagination,
  SpaceBetween,
} from "@amzn/awsui-components-react"
import Header from "@amzn/awsui-components-react/polaris/header"
import Table from "@amzn/awsui-components-react/polaris/table"
import { Opportunity } from "models/opportunity"
import { Task, TASK_CLOSED_NAMES } from "models/task"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import qs from "qs"
import React, { useMemo } from "react"
import { routeParams } from "routes"

import { COLUMN_DEFINITIONS } from "./tableConfig"

type Props = {
  showClosed?: boolean
  tasks?: Task[]
  loading: boolean
  error: any
  opportunity?: Opportunity
}

const ActivitiesTable: React.FC<Props> = ({
  showClosed,
  tasks,
  loading,
  opportunity,
}) => {
  const filteredTasks = useMemo(
    () =>
      (showClosed &&
        tasks?.filter((task) => TASK_CLOSED_NAMES.has(task.status))) ||
      tasks?.filter((task) => !TASK_CLOSED_NAMES.has(task.status)) ||
      [],
    [tasks, showClosed]
  )

  const { items, collectionProps, paginationProps } = useCollection(
    filteredTasks,
    {
      pagination: {
        pageSize: 10,
      },
      sorting: {},
      selection: {},
    }
  )

  return (
    <Container>
      <SpaceBetween size="l">
        <Header
          actions={
            !showClosed &&
            opportunity && (
              <NavigationButton
                iconAlign="right"
                href={`${routeParams.tasksCreate()}?${qs.stringify({
                  relatedId: opportunity.id,
                  relatedName: opportunity.name,
                  opportunity: {
                    workstreamName: opportunity.workstreamName,
                  },
                })}`}
                variant="primary"
              >
                Create Task
              </NavigationButton>
            )
          }
        >
          {(showClosed && "Activity History") || "Open Activities"}
        </Header>
        <Table
          {...collectionProps}
          loading={loading}
          loadingText="Loading tasks"
          columnDefinitions={COLUMN_DEFINITIONS}
          items={items}
          pagination={<Pagination {...paginationProps} />}
        />
      </SpaceBetween>
    </Container>
  )
}

export default ActivitiesTable
