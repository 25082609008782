import { useCollection } from "@amzn/awsui-collection-hooks"
import {
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  CollectionPreferences,
  CollectionPreferencesProps,
  ColumnLayout,
  Header,
  Multiselect,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter,
} from "@amzn/awsui-components-react"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import opportunities from "api/opportunities"
import keyBy from "lodash/keyBy"
import { Opportunity, OPPORTUNITY_STAGE } from "models/opportunity"
import EmptyState from "pmsa-polaris/components/EmptyState"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import useFlashbar, { FlashMessage } from "pmsa-polaris/hooks/useFlashbar"
import usePersistentState from "pmsa-polaris/hooks/usePersistentState"
import usePromise from "pmsa-polaris/hooks/usePromise"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import { paginationLabels } from "pmsa-polaris/utils"
import qs from "qs"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import routes, { routeParams } from "routes"

import { DEFAULT_COLUMN_IDS, SEARCHABLE_COLUMNS } from "./columnsNames"
import {
  COLUMN_DEFINITIONS,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from "./tableConfig"

const { sfdcBaseUrl } = config

// opportunity stage definitions
const DEFAULT_STAGE = { label: "Open Opportunities", value: "open" }

const STAGE_OPTIONS: OptionDefinition[] = [
  { label: "All Opportunities", value: "all" },
  DEFAULT_STAGE,
  { label: "Hygiene Opportunities", value: "hygiene" },
  { label: "Propensity Opportunities", value: "propensity" },
  { label: "Closed Opportunities", value: "closed" },
  ...OPPORTUNITY_STAGE.map((v) => ({
    label: v,
    value: v.toLowerCase(),
  })),
]

const STAGES_BY_VALUE = keyBy(STAGE_OPTIONS, "value")

const OpportunitiesTable = () => {
  const queryParams = useQueryString()
  const navigate = useNavigate()

  const { alias, stage: queryStage } = queryParams

  // manual loading
  const [manualLoading, setManualLoading] = useState(false)

  // flash messages
  const setFlashMessages = useFlashbar()

  // get opportunities
  const [
    {
      loading: getOpportunityLoading,
      error: getOpportunityError,
      data: getOpportunityData,
    },
    getOpportunities,
  ] = usePromise(opportunities.getAll)

  useEffect(() => {
    getOpportunities(true, alias)
  }, [getOpportunities, alias])

  const [getErrorMessage, setGetErrorMessage] = useState(
    "Failed to load opportunities"
  )

  useEffect(() => {
    if (
      getOpportunityError &&
      getOpportunityError.response?.data?.errorCode == "USER_NOT_FOUND"
    ) {
      getOpportunityError.response?.data?.message &&
        setGetErrorMessage(getOpportunityError.response.data.message)
    }
  }, [getOpportunityError])

  // update opportunities
  const [
    { loading: updateOpportunityLoading, error: updateOpportunityError },
    updateOpportunity,
  ] = usePromise(opportunities.update)

  // stage filter
  const stageOption = STAGES_BY_VALUE[queryStage] || DEFAULT_STAGE

  const stageChanged = useCallback(
    (option: OptionDefinition) => {
      navigate(`?${qs.stringify({ ...queryParams, stage: option.value })}`, {
        replace: true,
      })
    },
    [navigate, queryParams]
  )

  const matchesStage = (item: Readonly<Opportunity>, stage: string) => {
    if (stage === "all") return true
    if (stage === "hygiene" && item.hygieneCount && item.hygieneCount > 0)
      return true
    if (
      stage === "propensity" &&
      item.propensityCount &&
      item.propensityCount > 0
    )
      return true
    if (stage === "open")
      return !["Completed", "Closed Lost"].includes(item.stageName)
    else if (stage === "closed")
      return ["Completed", "Closed Lost"].includes(item.stageName)
    else return stage === item.stageName.toLowerCase()
  }

  const clearFilter = () => {
    actions.setFiltering("")
    stageChanged(DEFAULT_STAGE)
  }

  // partner filter
  const [partnerOptions, setPartnerOptions] = useState<OptionDefinition[]>([])
  const [selectedPartners, setSelectedPartners] = useState<OptionDefinition[]>(
    []
  )

  useEffect(() => {
    const tmp: OptionDefinition[] = []
    getOpportunityData?.forEach((item) => {
      if (
        tmp.find((element) => element.value == item.accountName) == undefined &&
        matchesStage(item, stageOption.value!)
      ) {
        tmp.push({ label: item.accountName, value: item.accountName })
      }
    })
    setPartnerOptions(tmp)
  }, [getOpportunityData, stageOption.value])

  // table preferences
  const [preferences, setPreferences] =
    usePersistentState<CollectionPreferencesProps.Preferences>(
      "thunder.opportunityTablePreferences",
      {
        pageSize: 10,
        visibleContent: DEFAULT_COLUMN_IDS,
        wrapLines: false,
      }
    )

  // actions button & handler
  const actionsHandler = (
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    if (event.detail.id == "edit") {
      selectedItems?.forEach((item) => {
        window.open(routeParams.opportunitiesEdit({ id: item.id }))
      })
    }
    if (event.detail.id.startsWith("ext")) {
      opportunityQuickExtend(event)
    }
  }

  const opportunityQuickExtend = async (
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    if (selectedItems != undefined) {
      const promises = []
      const flashMessages: FlashMessage[] = []
      const extendableHygieneIssues = [
        "Asleep",
        "Forgotten",
        "Stale",
        "Neglected",
      ]

      for (let i = 0; i < selectedItems.length; i++) {
        if (
          selectedItems[i].hygiene.length == 0 ||
          selectedItems[i].hygiene.every((hygieneString) =>
            extendableHygieneIssues.some(
              (validHygiene) => hygieneString === validHygiene
            )
          )
        ) {
          setManualLoading(true)

          const newOpp: Opportunity = selectedItems[i]
          const newDate = new Date(newOpp.closeDate)
          newDate.setDate(
            newDate.getDate() + parseInt(event.detail.id.substring(3))
          )
          switch (newOpp.stageName) {
            case OPPORTUNITY_STAGE[7]:
              newOpp.stageName = OPPORTUNITY_STAGE[1]
              break
            case OPPORTUNITY_STAGE[8]:
              newOpp.stageName = OPPORTUNITY_STAGE[1]
              newOpp.closedLostCategory = ""
              newOpp.closedLostReasonNotes = ""
              break
          }
          newOpp.closeDate = newDate.toISOString().split("T")[0]
          promises.push(updateOpportunity(newOpp.id, newOpp))
        } else {
          flashMessages.push({
            type: "error",
            content: `Opportunity "${selectedItems[i].name}" has hygiene issues and cannot be updated!`,
          })
        }
      }

      await Promise.allSettled(promises).then((results) => {
        results.forEach((result, index) => {
          if (result.status == "rejected") {
            flashMessages.push({
              type: "error",
              content: `Opportunity "${
                JSON.parse(result.reason.response.config.data).name
              }" could not be updated! (${
                result.reason.response.data.errorCode
              })`,
            })

            results.splice(index, 1)
          }
        })

        if (flashMessages.length === 0) {
          flashMessages.push({
            type: "success",
            content: "All opportunities successfully updated!",
          })
        } else if (results.length > 0) {
          flashMessages.push({
            type: "success",
            content: `Remaining ${
              results.length === 1 ? "opportunity" : "opportunities"
            } successfully updated!`,
          })
        }

        if (promises.length > 0) {
          getOpportunities(true, alias)
          setManualLoading(false)
        }
      })

      setFlashMessages(flashMessages)
    }
  }

  // table collection
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(getOpportunityData || [], {
    filtering: {
      empty: (
        <EmptyState
          errorText={(getOpportunityError && getErrorMessage) || undefined}
          title="No opportunities"
          subtitle="No opportunities to display."
          action={
            <NavigationButton href={routes.opportunitiesCreate}>
              Create Opportunity
            </NavigationButton>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can't find a match."
          action={<Button onClick={() => clearFilter()}>Clear filter</Button>}
        />
      ),
      filteringFunction: (item, filteringText) => {
        if (!matchesStage(item, stageOption.value!)) {
          return false
        }
        if (
          selectedPartners.length > 0 &&
          !selectedPartners.find((element) => element.value == item.accountName)
        ) {
          return false
        }
        return SEARCHABLE_COLUMNS.map(
          (key) => item[key as keyof Opportunity]
        ).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().indexOf(filteringText.toLowerCase()) > -1
        )
      },
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "propensityDays",
        },
        isDescending: false,
      },
    },
    selection: {},
  })

  // table component
  const { selectedItems } = collectionProps
  return (
    <Table
      {...collectionProps}
      loading={
        getOpportunityLoading || updateOpportunityLoading || manualLoading
      }
      loadingText="Loading opportunities"
      selectionType="multi"
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <TableHeader
          itemCount={getOpportunityData?.length}
          selectedItems={selectedItems}
          actionsHandler={actionsHandler}
        />
      }
      visibleColumns={preferences.visibleContent}
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      filter={
        <SpaceBetween size="l">
          <ColumnLayout columns={3}>
            <TextFilter
              {...filterProps}
              //countText={getMatchesCountText(filteredItemsCount!)}
              filteringPlaceholder="Search opportunities"
              filteringAriaLabel="Search opportunities"
            />
            <Select
              options={STAGE_OPTIONS}
              selectedOption={stageOption}
              onChange={(event) => {
                stageChanged(event.detail.selectedOption)
              }}
            />
            <Multiselect
              options={partnerOptions}
              selectedOptions={selectedPartners}
              onChange={(event) =>
                setSelectedPartners([...event.detail.selectedOptions])
              }
              placeholder="Select a Partner"
              loadingText="Loading opportunities"
              statusType={
                getOpportunityLoading
                  ? "loading"
                  : partnerOptions.length == 0
                  ? "error"
                  : "finished"
              }
              errorText={
                partnerOptions.length == 0
                  ? "No Partners found"
                  : "There was an error"
              }
            />
          </ColumnLayout>
        </SpaceBetween>
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: "Page size",
            options: PAGE_SIZE_OPTIONS,
          }}
          wrapLinesPreference={{
            label: "Wrap lines",
            description: "Check to see all the text and wrap the lines",
          }}
          visibleContentPreference={{
            title: "Select visible columns",
            options: VISIBLE_CONTENT_OPTIONS,
          }}
        />
      }
    />
  )
}

// table header component
const TableHeader: React.FC<{
  itemCount?: number
  selectedItems?: Readonly<Opportunity[]>
  actionsHandler: Function
}> = ({ itemCount, selectedItems, actionsHandler }) => {
  const selectedItem = selectedItems && selectedItems[0]

  return (
    <Header
      variant="h2"
      counter={
        (itemCount &&
          (selectedItems
            ? `(${selectedItems.length}/${itemCount})`
            : `(${itemCount})`)) ||
        ""
      }
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <ButtonDropdown
            items={[
              {
                id: "view-in-sfdc",
                text: "View in SFDC",
                disabled: selectedItems?.length != 1,
                href: `${sfdcBaseUrl}/${selectedItem?.id ?? ""}`,
                external: true,
              },
              {
                id: "new-copy",
                text: "New Copy",
                disabled: selectedItems?.length != 1,
                href: `${routeParams.opportunitiesCreate()}?${qs.stringify(
                  selectedItem
                )}`,
              },
              {
                id: "edit",
                text: "Edit",
                disabled: selectedItems?.length == 0,
              },
              {
                id: "extend-close-date",
                text: "Extend Close Date",
                disabled: selectedItems?.length == 0,
                items: [
                  {
                    id: "ext30",
                    text: "30 days",
                  },
                  {
                    id: "ext60",
                    text: "60 days",
                  },
                  {
                    id: "ext90",
                    text: "90 days",
                  },
                ],
              },
            ]}
            expandableGroups
            onItemClick={(event) => actionsHandler(event)}
          >
            Actions
          </ButtonDropdown>
          <NavigationButton variant="primary" href={routes.opportunitiesCreate}>
            Create Opportunity
          </NavigationButton>
        </SpaceBetween>
      }
    >
      Opportunities (Outcomes)
    </Header>
  )
}

export default OpportunitiesTable
