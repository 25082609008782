import { Box, Grid, SpaceBetween, Toggle } from "@amzn/awsui-components-react"
import { OptionsLoadItemsDetail } from "@amzn/awsui-components-react/polaris/internal/components/dropdown/interfaces"
import { DropdownStatusProps } from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status"
import { NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events"
import { SelectProps } from "@amzn/awsui-components-react/polaris/select"
import campaignsApi, { CampaignResponse } from "api/campaigns"
import opportunitiesApi, { OpportuniyResponse } from "api/opportunities"
import { useFormikContext } from "formik"
import keyBy from "lodash/keyBy"
import { Opportunity } from "models/opportunity"
import {
  InputFormField,
  SegmentedControlFormField,
  SelectFormField,
} from "pmsa-polaris/components/FormFields"
import RefreshButton from "pmsa-polaris/components/RefreshButton"
import useDebounce from "pmsa-polaris/hooks/useDebounce"
import { useCallback, useEffect, useState } from "react"

let fetchOpportunitiesPromise:
  | Promise<OpportuniyResponse[]>
  | Promise<OpportuniyResponse>
  | null = null
const globalFetchOpportunities = (
  initialOptions: SelectProps.Option,
  id?: string
) => {
  if (!fetchOpportunitiesPromise) {
    if (initialOptions.value !== undefined) {
      fetchOpportunitiesPromise = opportunitiesApi.get(initialOptions.value)
    } else if (id) {
      fetchOpportunitiesPromise = opportunitiesApi.getAll(false, id)
    } else {
      fetchOpportunitiesPromise = opportunitiesApi.getAll(false)
    }
  }
  return fetchOpportunitiesPromise
}

export const OpportunityPicker: React.FC<Props> = ({
  onSelected,
  initialOptions = [],
}) => {
  const [options, setOptions] = useState<SelectProps.Options>(initialOptions)
  const [status, setStatus] =
    useState<DropdownStatusProps.StatusType>("loading")
  const [errorText, setErrorText] = useState<string>()
  const [refresh, setRefresh] = useState(false)
  const [opportunitiesById, setOpportunitiesById] = useState<
    Record<string, OpportuniyResponse>
  >({})
  const [differentOwner, setDifferentOwner] = useState(false)
  const [ownerAlias, setOwnerAlias] = useState("")
  const { values, initialValues, setFieldValue } = useFormikContext()

  useEffect(() => {
    let mounted = true
    const fetchOpportunities = async () => {
      try {
        setOptions(initialOptions)
        setOpportunitiesById({})
        setStatus("loading")
        setErrorText(undefined)
        const initOptions = initialOptions[0] || {}
        const opportunities = differentOwner
          ? await globalFetchOpportunities(initOptions, ownerAlias)
          : await globalFetchOpportunities(initOptions)
        if (mounted) {
          const options: SelectProps.Option[] = []
          const byId: Record<string, Opportunity> = {}

          const opps = Array.isArray(opportunities)
            ? opportunities
            : [opportunities]

          opps.forEach((opportunity) => {
            const {
              name,
              title,
              id,
              fiscalYear,
              accountName,
              workstreamId,
              workstreamName,
            } = opportunity
            byId[opportunity.id] = opportunity
            options.push({
              value: id || undefined,
              label: title || undefined,
              labelTag: accountName || undefined,
              description: `${fiscalYear}`,
              filteringTags: [
                id || "",
                accountName || "",
                ...((workstreamId && [workstreamId]) || []),
              ],
              tags: (workstreamName && [workstreamName]) || undefined,
            })
          })
          setOpportunitiesById(keyBy(opportunities, "id"))
          setOptions(options)
          setStatus("finished")
        }
      } catch (error) {
        console.error(error)
        setStatus("error")
        setErrorText("Failed to get opportunities")
      }
    }
    fetchOpportunities()

    return () => {
      mounted = false
    }
  }, [ownerAlias, refresh, differentOwner, initialOptions])

  const onRefreshClick = useCallback(() => {
    fetchOpportunitiesPromise = null
    setRefresh((refresh) => !refresh)
  }, [])

  const handleOnChange: NonCancelableEventHandler<SelectProps.ChangeDetail> =
    useCallback(
      (e) => {
        if (onSelected) {
          onSelected(
            "opportunity",
            opportunitiesById[e.detail.selectedOption.value!]
          )
        }
      },
      [onSelected, opportunitiesById]
    )

  return (
    <SpaceBetween size="xs">
      {!differentOwner ? (
        <Grid gridDefinition={[{ colspan: 12 }]}>
          <Toggle
            onChange={({ detail }) => {
              setFieldValue("ownerAliasField", "")
              setDifferentOwner(detail.checked)
            }}
            checked={differentOwner}
            description="Toggle to enter alias of another Opp owner"
          >
            Attach to Another Person&apos;s Opp
          </Toggle>
        </Grid>
      ) : (
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 6 }]}>
          <div>
            <Toggle
              onChange={({ detail }) => {
                setFieldValue("ownerAliasField", "")
                setDifferentOwner(detail.checked)
              }}
              checked={differentOwner}
              description="Toggle to enter alias of another Opp owner"
            >
              Attach to Another Person&apos;s Opp
            </Toggle>
          </div>
          <div>
            <InputFormField
              name="ownerAliasField"
              inputMode="text"
              label="Alias or Email"
              description=""
              required
              onChange={({ detail }) => setOwnerAlias(detail.value)}
            />
          </div>
          <div>
            <Box color="text-status-info" variant="p">
              Enter the owner&apos;s alias then click the refresh button next to
              the opportunity list. If the owner&apos;s email does not end in
              @amazon.com then you can enter the full email address.
            </Box>
          </div>{" "}
        </Grid>
      )}

      <SelectFormField
        filteringType="auto"
        statusType={status}
        errorText={errorText}
        options={options}
        loadingText={
          (status === "loading" && "Loading opportunities") || undefined
        }
        label="Related Opportunity"
        description="Select the related opportunity"
        required
        name="relatedId"
        onChange={handleOnChange}
      >
        <RefreshButton
          loading={status === "loading"}
          onClick={onRefreshClick}
        />
      </SelectFormField>
    </SpaceBetween>
  )
}

const CampaignPicker: React.FC<Props> = ({
  onSelected,
  initialOptions = [],
}) => {
  const [options, setOptions] = useState<SelectProps.Options>(initialOptions)
  const [status, setStatus] =
    useState<DropdownStatusProps.StatusType>("pending")
  const [campaignsById, setCampaignsById] = useState<
    Record<string, CampaignResponse>
  >({})

  const debouncedAutoComplete = useDebounce(campaignsApi.search, 200)

  const handleLoadItems: NonCancelableEventHandler<OptionsLoadItemsDetail> = ({
    detail: { filteringText },
  }) => {
    if (filteringText && filteringText.length > 2) {
      const fetch = async () => {
        try {
          setStatus("loading")
          const campaigns = await debouncedAutoComplete(filteringText)
          const options: SelectProps.Option[] = campaigns.map((v) => ({
            value: v.id,
            label: v.name,
          }))
          setCampaignsById(keyBy(campaigns, "id"))
          setOptions(options)
          setStatus("finished")
        } catch (e) {
          console.error(e)
          setStatus("error")
        }
      }
      fetch()
    }
  }

  const handleOnChange: NonCancelableEventHandler<SelectProps.ChangeDetail> =
    useCallback(
      (e) => {
        if (onSelected) {
          onSelected("campaign", campaignsById[e.detail.selectedOption.value!])
        }
      },
      [campaignsById, onSelected]
    )

  return (
    <SelectFormField
      name="relatedId"
      required
      label="Related Campaign"
      description="Type then select the campaign name"
      filteringType="manual"
      onLoadItems={handleLoadItems}
      options={options}
      onChange={handleOnChange}
      statusType={status}
    />
  )
}

type Props = {
  onSelected?: (type: string, relatedObject: any) => void
  initialOptions?: SelectProps.Options
}

const RelatedToFormFields: React.FC<Props> = ({
  onSelected,
  initialOptions,
}) => {
  const {
    values: { relatedType },
  } = useFormikContext()

  return (
    <SpaceBetween size="l">
      <SegmentedControlFormField
        name="relatedType"
        label="Related to"
        options={[
          { text: "Opportunity", id: "opportunity" },
          { text: "Campaign", id: "campaign" },
        ]}
      />
      {relatedType === "opportunity" && (
        <OpportunityPicker
          onSelected={onSelected}
          initialOptions={initialOptions}
        />
      )}
      {relatedType === "campaign" && (
        <CampaignPicker
          onSelected={onSelected}
          initialOptions={initialOptions}
        />
      )}
    </SpaceBetween>
  )
}

export default RelatedToFormFields
