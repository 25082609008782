import { TableProps } from "@amzn/awsui-components-react/polaris/table"
import { TaskResponse } from "api/tasks"
import NavigationLink from "pmsa-polaris/components/NavigationLink"
import { routeParams } from "routes"

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<TaskResponse>[] = [
  {
    id: "taskSubject",
    cell: (item) => (
      <NavigationLink href={routeParams.tasksDetails({ id: item.id })}>
        {item.subject}
      </NavigationLink>
    ),
    header: "Subject",
    minWidth: "100px",
  },
  {
    id: "status",
    header: "Status",
    cell: (item) => item.status,
    minWidth: "100px",
  },
  {
    id: "closeDate",
    header: "Close Date",
    cell: (item) => item.activityDate,
    minWidth: "100px",
  },
  {
    id: "saActivity",
    header: "SA Activity",
    cell: (item) => item.saActivity,
    minWidth: "100px",
  },
  {
    id: "bdActivityType",
    header: "BD Activity Type",
    cell: (item) => item.bdActivityType,
    minWidth: "100px",
  },
  {
    id: "timeSpentHrs",
    header: "Time Spent (Hrs)",
    cell: (item) => item.timeSpentHrs,
    minWidth: "100px",
  },
]
