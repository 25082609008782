import { Link, SelectProps } from "@amzn/awsui-components-react"
import Button from "@amzn/awsui-components-react/polaris/button"
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout"
import Container from "@amzn/awsui-components-react/polaris/container"
import Header from "@amzn/awsui-components-react/polaris/header"
import Input from "@amzn/awsui-components-react/polaris/input"
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import tasksApi from "api/tasks"
import { TaskRequest } from "api/tasks"
import { Wizard } from "components/FormikWizard"
import GeoRegionSelector from "components/GeoRegionSelector"
import { useFormikContext } from "formik"
import _ from "lodash"
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPE } from "models/task"
import moment from "moment"
import { ReviewStep } from "pages/CreateBuilderGoals/ReviewStep"
import RelatedToFormFields from "pages/CreateUpdateTask/RelatedToFormFields"
import {
  DatePickerFormField,
  InputFormField,
  SegmentedControlFormField,
  SelectFormField,
  TextAreaFormField,
} from "pmsa-polaris/components/FormFields"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { toOptions } from "pmsa-polaris/utils"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { routeParams } from "routes"
import * as Yup from "yup"

import { ACTIVITY_CATALOG, BUILDER_ACTIVITY_RECORD } from "../../models/builder"
import ToolsPanel from "./ToolsPanel"

// https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Reportingv2/#HReportingBuilderGoals

type BuildComments = {
  record: BUILDER_ACTIVITY_RECORD
  comments: string
  participants: string
  csat: string
  url: string
}

export const buildComments = (value: BuildComments) => {
  return value.record.outcome.comments === "speaking"
    ? `Participants: ${Number(value.participants).toString()}\nCSAT: ${Number(
        value.csat
      ).toString()}\nURL: ${value.url}`
    : value.record.outcome.comments === "url"
    ? value.url
    : value.comments
}

export const WizardContent = () => {
  const STATUS_OPTIONS = toOptions(...TASK_STATUS)
  const navigate = useNavigate()
  const { setContext } = useAppContext()
  const [activity, setActivity] = useState("")
  const [subActivity, setSubActivity] = useState("")

  const initialRecord: BUILDER_ACTIVITY_RECORD = {
    subActivity: "",
    activity: "",
    bdActivity: "",
    saActivity: "",
    linked: {
      type: "",
      parent: "",
      sfdcId: "",
    },
    outcome: {
      type: "",
      comments: "",
    },
  }

  const [selectedRecord, setSelectedRecord] = useState(initialRecord)

  useEffect(() => {
    //console.log(selectedRecord)
  }, [activity, selectedRecord, subActivity])

  const initialValues = {
    activity: "",
    subActivity: "",
    linked: "",
    title: "",
    activityDate: moment().format("YYYY-MM-DD"),
    comments: "",
    participants: "",
    csat: "",
    url: "",
    relatedId: "",
    relatedType: "",
    timeSpentHrs: "",
    type: TASK_TYPE[0],
    status: TASK_STATUS[2],
    priority: TASK_PRIORITY[0],
    record: initialRecord,
    urlComments: false,
    speakingComments: false,
  }

  const ACTIVITY_TYPES = [...new Set(ACTIVITY_CATALOG.map((x) => x.activity))]
  const SFDC_PARENTS = [
    ...new Set(ACTIVITY_CATALOG.map((x) => x.linked.parent)),
  ]
  const LINKED_TYPES = [...new Set(ACTIVITY_CATALOG.map((x) => x.linked.type))]

  const getSubActivity = () => {
    const filtered = ACTIVITY_CATALOG.filter((x) => x.activity === activity)
    return [...new Set(filtered.map((x) => x.subActivity))]
  }

  const getActivityItem = (activity: string, subActivity: string) => {
    return ACTIVITY_CATALOG.filter(
      (x) => x.activity === activity && x.subActivity === subActivity
    )
  }

  const [
    { loading: createTaskLoading, data: createdTask, error: createTaskError },
    createTask,
  ] = usePromise(tasksApi.create)

  const [relatedToInitialOptions, setRelatedToInitialOptions] =
    useState<SelectProps.Options>(() => {
      return []
    })

  const isLoading = createTaskLoading

  const setFlashMessages = useFlashbar()

  const handleInfoClicked = () => (e: CustomEvent<BaseNavigationDetail>) => {
    e.preventDefault()
    setContext({ toolsOpen: true })
  }

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
          `Step ${stepNumber} of ${stepsCount}`,
        cancelButton: "Cancel",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: "Create activity",
        optional: "optional",
      }}
      onSubmit={async (_, bag) => {
        bag.setSubmitting(true)
        const { comments, csat, url, participants, record } = _
        const generatedComments = buildComments({
          comments,
          csat,
          url,
          participants,
          record,
        })

        const taskRequest: TaskRequest = {
          title: _.title,
          description: generatedComments,
          activityDate: _.activityDate,
          status: _.status,
          priority: _.priority,
          timeSpentHrs: _.timeSpentHrs,
          bdActivityType: _.record.bdActivity,
          saActivity: _.record.saActivity,
          relatedType: _.relatedType,
          type: _.type,
          workstreamId: _.record.outcome.type,
          relatedId: _.relatedId,
        }

        try {
          const newTask = await tasksApi.create(taskRequest)
          bag.setStatus({ success: true })
          setFlashMessages([
            {
              content: "Task created successfully",
              presist: true,
              type: "success",
            },
          ])

          navigate(
            routeParams.tasksDetails({
              id: newTask.id,
            })
          )
        } catch (error) {
          console.error(error)
          bag.setStatus({ success: false })
          bag.setSubmitting(false)

          setFlashMessages([
            {
              content: "There was an issue with creating task.",
              presist: true,
              type: "error",
            },
          ])
        }
      }}
      onCancel={() => {
        navigate(routeParams.builderGoals())
      }}
      initialValues={initialValues}
      steps={[
        {
          validationSchema: Yup.object({
            activity: Yup.string().required(),
          }),
          onSubmit: async (_, bag) => {
            setActivity(_.activity)
          },
          title: "Choose Activity",
          info: (
            <Link variant="info" onFollow={handleInfoClicked()}>
              Info
            </Link>
          ),
          description:
            "Follow this wizard to help figure out where to create your activity.",
          content: (
            <SpaceBetween direction="vertical" size="xxl">
              <Container
                header={
                  <Header variant="h2">
                    Choose your builder activity type
                  </Header>
                }
              >
                <SpaceBetween direction="vertical" size="xxl">
                  <SelectFormField
                    name="activity"
                    label="Builder Activity"
                    filteringType="auto"
                    options={toOptions(...ACTIVITY_TYPES)}
                    required
                    description="Choose the type of builder activity"
                    //onChange={handleWorkstreamChanged}
                  />
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          ),
        },
        {
          validationSchema: Yup.object({
            subActivity: Yup.string().required(),
          }),
          onSubmit: async (_, bag) => {
            const record = getActivityItem(activity, _.subActivity)
            setSubActivity(_.subActivity)
            setSelectedRecord(record[0])
            bag.setFieldValue("record", record[0])
            bag.setFieldValue("linked", record[0].linked.type)
            bag.setFieldValue("relatedType", record[0].linked.parent)
            bag.setFieldValue("relatedId", record[0].linked.sfdcId)
            bag.setFieldValue(
              "urlComments",
              record[0].outcome.comments === "url"
            )
            bag.setFieldValue(
              "speakingComments",
              record[0].outcome.comments === "speaking"
            )

            record[0].linked.name && record[0].linked.sfdcId
              ? setRelatedToInitialOptions([
                  {
                    label: record[0].linked.name,
                    value: record[0].linked.sfdcId,
                  },
                ])
              : setRelatedToInitialOptions([])
          },
          title: "Choose Sub-Activity",
          info: (
            <Link variant="info" onFollow={handleInfoClicked()}>
              Info
            </Link>
          ),
          description: "",
          content: (
            <SpaceBetween direction="vertical" size="xxl">
              <Container
                header={
                  <Header variant="h2">
                    Choose your builder sub-activity type
                  </Header>
                }
              >
                <SpaceBetween direction="vertical" size="xxl">
                  <SelectFormField
                    name="subActivity"
                    label="Builder Sub Activity"
                    filteringType="auto"
                    options={toOptions(...getSubActivity())}
                    required
                    description="Choose the type of builder activity"
                    //onChange={handleWorkstreamChanged}
                  />
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          ),
        },
        {
          validationSchema: Yup.object({
            linked: Yup.string().required(),
            relatedId: Yup.string().required(),
            relatedType: Yup.string().required(),
          }),
          onSubmit: async (_, bag) => {
            const record = getActivityItem(_.activity, _.subActivity)
            bag.setFieldValue("comments", record[0].outcome.comments)
            setSelectedRecord(record[0])
            bag.setFieldValue(
              "urlComments",
              record[0].outcome.comments === "url"
            )
            bag.setFieldValue(
              "speakingComments",
              record[0].outcome.comments === "speaking"
            )
          },
          title: "Choose association",
          info: (
            <Link variant="info" onFollow={handleInfoClicked()}>
              Info
            </Link>
          ),
          description: "",
          content: (
            <SpaceBetween direction="vertical" size="xxl">
              <Container
                header={
                  <>
                    <Header variant="h2">
                      Choose where to associate your activity:
                    </Header>
                    <p>
                      If the field is disabled, we prepopulated with the
                      recommended option.
                    </p>
                  </>
                }
              >
                <SpaceBetween direction="vertical" size="xxl">
                  <SelectFormField
                    name="linked"
                    label="Linked Type"
                    filteringType="auto"
                    options={toOptions(...LINKED_TYPES)}
                    required
                    description=""
                    disabled={selectedRecord.linked.type !== ""}
                    //onChange={handleWorkstreamChanged}
                  />
                  <RelatedToFormFields
                    initialOptions={relatedToInitialOptions}
                    //onSelected={handleRelatedSelected}
                  />
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          ),
        },
        {
          validationSchema: Yup.object({
            title: Yup.string()
              .required("You must specify title.")
              .max(90, "Task subject can't be more than 90 characters.")
              .matches(/^[^\\]*$/, "Backslash is not allowed."),
            activityDate: Yup.date()
              .required("You must specify activity date")
              .typeError("You must specify date in the format YYYY/MM/DD")
              .min(
                "2020/01/01",
                "You must specify date greater than 2020/01/01"
              )
              .max(
                moment().add(1, "year"),
                "You must specify a task within 1 year from today"
              ),
            status: Yup.string().required(),
            comments: Yup.string().when(["speakingComments", "urlComments"], {
              is: (speakingComments: boolean, urlComments: boolean) =>
                !speakingComments && !urlComments,
              then: Yup.string()
                .max(32000, "Comments can't be more than 32000 characters.")
                .required("You must specify comments.")
                .matches(/^[^\\]*$/, "Backslash is not allowed."),
            }),
            participants: Yup.number().when("speakingComments", {
              is: true,
              then: Yup.number()
                .required("You must specify participants.")
                .min(0)
                .max(50000)
                .test(
                  "wholeNumber",
                  "participants must be a whole number",
                  (number = -1) => /^\d+(\.\d{})?$/.test(number?.toString())
                ),
            }),
            csat: Yup.number().when("speakingComments", {
              is: true,
              then: (schema) =>
                Yup.number()
                  .required("You must specify a csat.")
                  .min(0)
                  .max(5)
                  .test(
                    "maxDigitsAfterDecimal",
                    "csat can have a max of 2 decimal places",
                    (number = -1) =>
                      /^\d+(\.\d{1,2})?$/.test(number?.toString())
                  ),
            }),
            url: Yup.string().when(["speakingComments", "urlComments"], {
              is: (speakingComments: boolean, urlComments: boolean) =>
                speakingComments || urlComments,
              then: (schema) =>
                schema
                  .matches(
                    /^(https|http):\/\//i,
                    "Must start with http:// or https://"
                  )
                  .required(),
            }),
            timeSpentHrs: Yup.number()
              .typeError("You must specify a valid number")
              .positive("Time spent must be a positive number")
              .min(0, "Time spent must be greater than or equal to 0")
              .max(1000, "Time spent must be less than 1000")
              .transform((value, originalValue) =>
                /\s/.test(originalValue) ? NaN : value
              )
              .test(
                "decimaldigits",
                "You can specify a number up to 1 decimal place",
                (number) => (number ? Number.isInteger(number * 10) : true)
              ),
          }),
          title: "Fill out forms",
          content: (
            <Container
              header={
                <Header variant="h2">Fill out the remaining fields</Header>
              }
            >
              <SpaceBetween direction="vertical" size="l">
                <InputFormField
                  name="title"
                  inputMode="text"
                  label="Activity Title"
                  description="Please use a verbose title"
                  required
                />
                <ColumnLayout columns={3}>
                  <DatePickerFormField
                    label="Activity Date"
                    openCalendarAriaLabel={(selectedDate) =>
                      "Choose Date" +
                      (selectedDate ? `, selected date is ${selectedDate}` : "")
                    }
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                    name="activityDate"
                    description="The date your builder event completed"
                    required
                  />
                  <SelectFormField
                    name="status"
                    label="Status"
                    options={STATUS_OPTIONS}
                    description="Activity status"
                    required
                  />
                </ColumnLayout>
                <ColumnLayout columns={3}>
                  <InputFormField
                    name="timeSpentHrs"
                    inputMode="tel"
                    label="Time Spent (Hrs)"
                    description="The total time spent"
                  />
                </ColumnLayout>
                {selectedRecord.outcome.comments === "speaking" ? (
                  <>
                    <p>Enter the following fields to fill out comments:</p>
                    <ColumnLayout columns={3}>
                      <InputFormField
                        name="participants"
                        inputMode="text"
                        label="Number of Participants"
                        required
                      />
                      <InputFormField
                        name="csat"
                        inputMode="text"
                        label="Event CSAT"
                        required
                      />
                    </ColumnLayout>
                    <InputFormField
                      name="url"
                      inputMode="text"
                      label="Event URL"
                      required
                    />
                  </>
                ) : selectedRecord.outcome.comments === "url" ? (
                  <InputFormField
                    name="url"
                    inputMode="text"
                    label="Content URL"
                    required
                  />
                ) : (
                  <TextAreaFormField
                    name="comments"
                    label="Activity Comments"
                    description="Please use a verbose description to be able to understand your contribution."
                    required
                  />
                )}
              </SpaceBetween>
            </Container>
          ),
        },
        {
          title: "Review and create",
          content: <ReviewStep />,
        },
      ]}
    />
  )
}
