import { Alert, Spinner } from "@amzn/awsui-components-react"
import tasks from "api/tasks"
import DateTime from "pmsa-polaris/components/DateTime"
import DetailsDisplay from "pmsa-polaris/components/DetailsDisplay"
import NavigationLink from "pmsa-polaris/components/NavigationLink"
import config from "pmsa-polaris/config"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { capitalize } from "pmsa-polaris/utils"
import React, { useEffect } from "react"
import { routeParams } from "routes"

import { useGetTaskContext } from "."

export type Props = {
  id: string
}

const { sfdcBaseUrl } = config

export const DetailsSection: React.FC<Props> = ({ id }) => {
  const [{ loading, error, data: task }] = useGetTaskContext()
  return (
    (loading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (task && (
      <DetailsDisplay
        details={[
          ["ID", id],
          ["Name", task.subject],
          ["Account Name (Partner)", task.accountName],
          ["Stage", task.status],
          ["Type", task.type],
          ["Time Spent (Hrs)", task.timeSpentHrs],
          ["Builder Activity", task.builderActivity ? "Yes" : "No"],
          [
            capitalize(task.relatedType),
            // eslint-disable-next-line react/jsx-key
            task.relatedId && (
              <NavigationLink
                href={
                  task.relatedType === "opportunity"
                    ? routeParams.opportunitiesDetails({
                        id: task.relatedId,
                      })
                    : `${sfdcBaseUrl}/${task.relatedId ?? ""}`
                }
              >
                {task.relatedName}
              </NavigationLink>
            ),
          ],
          ["SA Activity", task.saActivity || "-"],
          ["BD Activity Type", task.bdActivityType || "-"],
          ["Comments", task.description],
          ["Due Date", task.activityDate],
          // eslint-disable-next-line react/jsx-key
          ["Last Modified Date", <DateTime date={task.lastModifiedDate} />],
        ]}
      />
    )) ||
    (error && (
      <Alert visible={true} type="error" header="Failed to fetch details">
        Could not get details due to an error
      </Alert>
    )) ||
    null
  )
}
