// copied from https://code.amazon.com/packages/FormikPolaris/blobs/mainline/--/src/components/wizard/wizard.tsx
// the Formik Polaris package is not available in CodeArtifact so creating component in this code base directly

import PolarisWizard, {
  WizardProps as PolarisWizardProps,
} from "@amzn/awsui-components-react/polaris/wizard"
import { Formik, FormikHelpers } from "formik"
import ErrorFocus from "pmsa-polaris/components/ErrorFocus"
import React, { useMemo, useState } from "react"

/**
 * Wizard Step with extra fields
 */
export interface Step<T> extends PolarisWizardProps.Step {
  /**
   * Optional validation schema that will get called for this step
   */
  validationSchema?: any | (() => any)
  /**
   * Optional onSubmit function that will be called when user navigates to next step
   * If this function rejects, navigation will be prevented.
   */
  onSubmit?: (values: T, bag: FormikHelpers<T>) => Promise<void>
}

interface WizardProps<T>
  extends Omit<PolarisWizardProps, "steps" | "onSubmit"> {
  /**
   * Optional handler for controlling wizard active step when used with activeStepIndex value. If no handler or activeStepIndex is passed the step value is handled internally.
   */
  setActiveStepIndex?: (stepNumber: number) => void
  /**
   * validateOnBlur for Formik
   */
  validateOnBlur?: boolean
  /**
   * validateOnChange for Formik
   */
  validateOnChange?: boolean
  /**
   * validateOnMount for Formik
   */
  validateOnMount?: boolean
  /**
   * enableReinitialize for Formik
   */
  enableReinitialize?: boolean
  /**
   * InitialValues for formik
   */
  initialValues: T
  /**
   * Wizard steps that have validationSchema and onSubmit Values
   */
  steps: Step<T>[]
  /**
   * OnSubmit function that has FormikValues available
   */
  onSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void
  /**
   * Scroll to the first error key in Formik instance.
   */
  scrollToError?: boolean

  /**
   * Scroll to the first error key in Formik instance.
   */
  prompt?: React.ReactNode
}

/**
 * Polaris Wizard component that has a formik instance.
 *
 * Wizard has a Formik instance whose children are each page of the
 * multi-step form. The form is submitted on each forward transition (can only
 * progress with valid input), whereas a backwards step is allowed with
 * incomplete data. A snapshot of form state is used after each
 * transition. Each page has an optional submit handler, and the top-level
 * submit is called when the final page is submitted.
 */
// eslint-disable-next-line react/function-component-definition
export function Wizard<T>({
  enableReinitialize = false,
  scrollToError = true,
  steps,
  initialValues,
  onSubmit,
  onNavigate,
  activeStepIndex,
  setActiveStepIndex,
  prompt,
  validateOnBlur,
  validateOnChange,
  validateOnMount,
  ...props
}: WizardProps<T>) {
  const [internalStepIndex, setInternalStepIndex] = React.useState(
    activeStepIndex || 0
  )
  const [snapshot, setSnapshot] = useState(initialValues)
  const stepConfig = useMemo(
    () =>
      setActiveStepIndex && activeStepIndex
        ? { step: steps[activeStepIndex], index: activeStepIndex }
        : { step: steps[internalStepIndex], index: internalStepIndex },
    [activeStepIndex, internalStepIndex, setActiveStepIndex, steps]
  )

  const next = (values: T) => {
    setSnapshot(values)
  }

  const previous = (values: T) => {
    setSnapshot(values)
  }

  const handleSubmit = async (
    values: T,
    bag: FormikHelpers<T>
  ): Promise<void> => {
    if (stepConfig.step.onSubmit) {
      await stepConfig.step.onSubmit(values, bag)
    }

    bag.setTouched({})
    bag.setStatus({})
    next(values)
  }

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      validationSchema={stepConfig.step.validationSchema}
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      validateOnMount={validateOnMount}
    >
      {(formik) => (
        <>
          <PolarisWizard
            isLoadingNextStep={formik.isSubmitting}
            activeStepIndex={stepConfig.index}
            onSubmit={async () => {
              await formik.submitForm()
              onSubmit?.(formik.values, formik)
            }}
            steps={steps}
            onNavigate={async (event) => {
              onNavigate?.(event)
              // A backwards step is allowed with incomplete data
              if (
                event.detail.reason === "previous" ||
                event.detail.reason === "step"
              ) {
                if (event.detail.requestedStepIndex < stepConfig.index) {
                  previous(formik.values)
                  if (!setActiveStepIndex) {
                    setInternalStepIndex(event.detail.requestedStepIndex)
                  } else {
                    setActiveStepIndex(event.detail.requestedStepIndex)
                  }
                }
              }
              // Wizard can only progress with valid input
              if (
                event.detail.reason === "next" ||
                event.detail.reason === "step"
              ) {
                if (event.detail.requestedStepIndex > stepConfig.index) {
                  try {
                    await formik.submitForm()
                  } catch (e) {
                    // Do not progress if form submission fails
                    return
                  }

                  const errors = await formik.validateForm()
                  // Check if errors is empty
                  if (Object.keys(errors).length === 0) {
                    next(formik.values)
                    if (!setActiveStepIndex) {
                      setInternalStepIndex(event.detail.requestedStepIndex)
                    } else {
                      setActiveStepIndex(event.detail.requestedStepIndex)
                    }
                  }
                }
              }
            }}
            {...props}
          />
          {scrollToError && <ErrorFocus />}
          {prompt}
        </>
      )}
    </Formik>
  )
}
