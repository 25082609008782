import MainLayout from "pmsa-polaris/components/MainLayout"

import FormContent from "./FormContent"
import toolsTopics from "./tools-topics"

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
const CreateUpdateOpportunity = () => (
  <MainLayout contentType="form" toolsTopics={toolsTopics}>
    <FormContent />
  </MainLayout>
)

export default CreateUpdateOpportunity
