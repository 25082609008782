import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const ToolsPanel = () => (
  <HelpPanel header={<h2>Create Builder Activities</h2>}>
    <p>
      This wizard will guide you through creating activities (SFDC tasks) to
      count toward your builder goals. You can read more about our builder goals
      in this{" "}
      <a
        href="https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Reportingv2#HReportingBuilderGoals."
        target="_blank"
        rel="noreferrer"
      >
        wiki
      </a>
    </p>
    <p></p>
    <p>
      As you progress through the wizard it will pre-populate some values for
      you based upon your selections. These are all based on information in the
      above wiki.
    </p>
  </HelpPanel>
)

export default ToolsPanel
