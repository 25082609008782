import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react"
import tasks from "api/tasks"
import MainLayout from "pmsa-polaris/components/MainLayout"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import createPromiseContext from "pmsa-polaris/context/PromiseContext"
import qs from "qs"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import routes, { routeParams } from "routes"

import { DetailsSection } from "./DetailsSection"

const [GetTaskContextProvider, useGetTaskContext] = createPromiseContext(
  tasks.get
)

export { useGetTaskContext }
const { sfdcBaseUrl } = config

const DetailsPage: React.FC = () => {
  const { id: idParam } = useParams()
  const id = idParam!

  const [{ data: task, loading }, getTask] = useGetTaskContext()

  useEffect(() => {
    getTask(id)
  }, [getTask, id])

  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="m">
              <Button
                iconAlign="right"
                iconName="external"
                href={`${sfdcBaseUrl}/${id ?? ""}`}
                target="_blank"
              >
                View in SFDC
              </Button>
              <NavigationButton
                disabled={loading}
                href={`${routes.tasksCreate}?${qs.stringify({
                  ...task,
                })}`}
              >
                New Copy
              </NavigationButton>
              <NavigationButton
                variant="primary"
                iconAlign="right"
                iconName="edit"
                href={routeParams.tasksEdit({ id })}
              >
                Edit
              </NavigationButton>
            </SpaceBetween>
          }
        >
          Task Details
        </Header>
      }
    >
      <DetailsSection id={id} />
    </Container>
  )
}

const Details = () => (
  <MainLayout
    content={
      <SpaceBetween size="l">
        <GetTaskContextProvider>
          <DetailsPage />
        </GetTaskContextProvider>
      </SpaceBetween>
    }
    contentType="default"
  />
)

export default Details
