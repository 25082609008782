import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const topics = {
  // List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
  main: (
    <HelpPanel header={<h2>Task (Activity)</h2>}>
      <p>
        Filling out the form on this page will create an opportunity with a task
        within SFDC. You can also create opportunities directly within SFDC.
        However it is advised to use this interface to ensure your entries
        adhere to strict hygiene requirements.
      </p>
    </HelpPanel>
  ),
  helpWorkstreamType: (
    <HelpPanel header={<h2>Workstream</h2>}>
      <p>
        Workstreams are discrete and enabling activities that are designed to
        help an AWS partner grow in capabilities, practices, partner offerings,
        skills, and so on. With the ultimate goal of helping each partner to
        increase the number of partner originated opportunities, each workstream
        is tracked so that we can observe and measure the outcomes. Reference
        the following Wiki page for more details on each workstream type:
      </p>
      <li>
        <a
          href="https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Workstreams"
          target="_blank"
          rel="noreferrer noopener"
        >
          PMSA Workstreams
        </a>
      </li>
    </HelpPanel>
  ),
} as const

export default topics
