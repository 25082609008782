const routes = {
  home: "/",
  opportunitiesList: "/opportunities",
  opportunitiesCreate: "/opportunities/create",
  opportunitiesEdit: "/opportunities/:id/edit",
  opportunitiesDetails: "/opportunities/:id",
  opportunitiesTasks: "/opportunities/:opportunityId/tasks/",
  tasksList: "/tasks",
  tasksCreate: "/tasks/create",
  tasksEdit: "/tasks/:id/edit",
  tasksDetails: "/tasks/:id",
  builderGoalsCreate: "/buildergoals/create",
  builderGoals: "/buildergoals",
} as const

import { createRouteParams } from "pmsa-polaris/routeUtils"

export default routes
export const routeParams = createRouteParams(routes)
