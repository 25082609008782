import {
  FormField,
  ProgressBar,
  SelectProps,
  TextContent,
} from "@amzn/awsui-components-react"
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout"
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events"
import Link from "@amzn/awsui-components-react/polaris/link"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import GeoRegionSelector from "components/GeoRegionSelector"
import { useWorkstreamCatalog } from "context/WorkstreamCatalogContext"
import { getIn, useFormikContext } from "formik"
import { Opportunity } from "models/opportunity"
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPE } from "models/task"
import {
  DatePickerFormField,
  InputFormField,
  SegmentedControlFormField,
  SelectFormField,
  TextAreaFormField,
} from "pmsa-polaris/components/FormFields"
import useNamePrefix from "pmsa-polaris/hooks/useNamePrefix"
import { toOptions } from "pmsa-polaris/utils"
import { useCallback, useEffect, useMemo, useState } from "react"

import { TaskFormValues } from "./FormContent"
import RelatedToFormFields from "./RelatedToFormFields"
import topics from "./tools-topics"

type Props = {
  taskId?: string
  namePrefix?: string
  hideRelatedPicker?: boolean
  relatedToInitialOptions?: SelectProps.Options
  opportunityData?: Pick<Opportunity, "workstreamName">
  onInfoClicked: (
    topic: keyof typeof topics
  ) => (e: CustomEvent<BaseNavigationDetail>) => void
}

const STATUS_OPTIONS = toOptions(...TASK_STATUS)
const PRIORITY_OPTIONS = toOptions(...TASK_PRIORITY)
const TYPE_OPTIONS = toOptions(...TASK_TYPE)

const FieldContent: React.FC<Props> = ({
  namePrefix,
  onInfoClicked,
  hideRelatedPicker,
  opportunityData,
  children,
  taskId,
  relatedToInitialOptions,
}) => {
  const getName = useNamePrefix(namePrefix)

  const [relatedOpportunityData, setRelatedOpportuityData] =
    useState(opportunityData)

  const [currentWorkstreamName, setCurrentWorkstreamName] = useState("")

  const { setFieldValue, values, errors } = useFormikContext<TaskFormValues>()
  const {
    workstreamName,
    milestoneId,
    activityDate,
    relatedId,
    relatedType,
    title,
  } = getIn(values, namePrefix ?? "", {})
  const disabled = (!taskId && !relatedId) || false

  const {
    bdActivityOptions,
    saActivityOptions,
    taskOptions,
    streamsById,
    streamsByName,
  } = useWorkstreamCatalog()

  const handleRelatedSelected = useCallback((type, data) => {
    if (type === "opportunity") {
      setRelatedOpportuityData(data)
    }
  }, [])

  const filteredTaskOptions = useMemo(() => {
    if (
      streamsByName &&
      taskOptions &&
      relatedOpportunityData &&
      relatedOpportunityData.workstreamName
    ) {
      const newTaskOptions = [...taskOptions]
      const foundIndex = taskOptions.findIndex(
        (option) =>
          option.label ===
          streamsByName.workstreams[relatedOpportunityData.workstreamName!]
            ?.category
      )
      if (foundIndex > -1) {
        const [existing] = newTaskOptions.splice(foundIndex, 1)
        const newOption = { ...existing }
        newOption.label = `${existing.label} (via opportunity)`
        newTaskOptions.unshift(newOption)
      }

      return newTaskOptions
    }
    return taskOptions
  }, [streamsByName, taskOptions, relatedOpportunityData])

  const [milestoneOptions, setMilestoneOptions] = useState<
    {
      id: string
      text: string
    }[]
  >()

  const handleWorkstreamChanged = (e: any) => {
    setCurrentWorkstreamName(e.detail.selectedOption.value)
    setMilestoneOptions([])
    setFieldValue(getName("milestoneId"), "")
    console.log(bdActivityOptions)
    console.log(currentWorkstreamName)
  }

  useEffect(() => {
    if (streamsByName) {
      const optionGroup = streamsByName?.tasks[workstreamName]?.milestones?.map(
        (v) => ({
          id: v.id,
          text: v.name,
        })
      )
      if (optionGroup) {
        setMilestoneOptions(optionGroup)
        if (optionGroup.length == 1) {
          setFieldValue(getName("milestoneId"), optionGroup[0].id)
        }
      }
    }
  }, [getName, setFieldValue, streamsByName, workstreamName])

  const milestoneProgress = parseInt(milestoneId)

  const getFiscalYear = (closeDateString: string) =>
    closeDateString && !Number.isNaN(closeDateString)
      ? parseInt(
          new Date(closeDateString).getFullYear().toString().substring(2)
        )
      : parseInt(new Date().getFullYear().toString().substring(2))

  const getFiscalYearString = (closeDateString: string) =>
    `FY${getFiscalYear(closeDateString)}`

  const taskName = [getFiscalYearString(activityDate), title || "[Title]"].join(
    "-"
  )

  return (
    <SpaceBetween size="l">
      <FormField
        label="Task Name"
        description="Formatted task name based on selections"
      >
        <TextContent>
          <strong>{taskName}</strong>
        </TextContent>
      </FormField>

      {!hideRelatedPicker && (
        <RelatedToFormFields
          initialOptions={relatedToInitialOptions}
          onSelected={handleRelatedSelected}
        />
      )}
      <SelectFormField
        name={getName("workstreamName")}
        label="Workstream"
        filteringType="auto"
        disabled={!filteredTaskOptions || disabled}
        options={filteredTaskOptions}
        required
        info={
          <Link variant="info" onFollow={onInfoClicked("helpWorkstreamType")}>
            Info
          </Link>
        }
        description="Choose the appropriate Workstream from the Catalog"
        onChange={handleWorkstreamChanged}
      />

      <InputFormField
        name={getName("title")}
        inputMode="text"
        label="Title"
        disabled={disabled}
        description="Title"
        required
      />

      <SelectFormField
        filteringType="auto"
        name={getName("bdActivityType")}
        label="BD Activity Type"
        options={
          currentWorkstreamName
            ? bdActivityOptions?.filter(
                (option) => option.label == currentWorkstreamName
              )
            : bdActivityOptions
        }
        disabled={disabled}
        description="The proper BD activity type selected as per the below Activity Tracking Guidelines."
        required
      />

      <SelectFormField
        filteringType="auto"
        name={getName("saActivity")}
        label="SA Activity"
        options={
          currentWorkstreamName
            ? saActivityOptions?.filter(
                (option) => option.label == currentWorkstreamName
              )
            : saActivityOptions
        }
        disabled={disabled}
        description="The proper SA activity selected as per the below Activity Tracking Guidelines."
        required
      />

      <ColumnLayout columns={3}>
        <DatePickerFormField
          label="Due date"
          openCalendarAriaLabel={(activityDate: string | null) =>
            "Choose Date" +
            (activityDate ? `, selected date is ${activityDate}` : "")
          }
          nextMonthAriaLabel="Next month"
          placeholder="YYYY/MM/DD"
          previousMonthAriaLabel="Previous month"
          todayAriaLabel="Today"
          disabled={disabled}
          name={getName("activityDate")}
          description="The date of the actual activity"
          required
        />

        <InputFormField
          name={getName("timeSpentHrs")}
          inputMode="tel"
          disabled={disabled}
          label="Time Spent (Hrs)"
          description="The total time spent excluding travel to complete the activity."
        />
      </ColumnLayout>

      <ColumnLayout columns={3}>
        <SelectFormField
          name={getName("status")}
          label="Status"
          disabled={disabled}
          options={STATUS_OPTIONS}
          description="Status for organizing work during engagement. Should be set as completed, once the activity is completed."
          required
        />

        <SelectFormField
          name={getName("priority")}
          label="Priority"
          disabled={disabled}
          options={PRIORITY_OPTIONS}
          description="Manage the prioriy of the activity"
          required
        />
      </ColumnLayout>
      <ColumnLayout columns={3}>
        <SelectFormField
          name={getName("type")}
          label="Type"
          disabled={disabled}
          options={TYPE_OPTIONS}
          description="Choose the type of activity performed"
          required
        />
      </ColumnLayout>

      <TextAreaFormField
        name={getName("description")}
        label="Comments"
        disabled={disabled}
        description="A third-party understandable description of the activity. Might need to include mandatory information as per Activity Tracking Guidelines."
      />

      {children}
    </SpaceBetween>
  )
}

export default FieldContent
