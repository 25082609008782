import { BaseOpportunityTask } from "./shared"

type TaskMilestone = {
  name: string
  id: string
}

export const TASK_STATUS = [
  "Not Started",
  "Disqualified",
  "Completed",
  "In Progress",
  "Waiting on someone else",
  "Deferred",
  "Unresponsive",
] as const

export const TASK_PRIORITY = ["Normal", "High", "Low"] as const

export const TASK_TYPE = [
  "Other",
  "Call",
  "Meeting",
  "Email",
  "Live Chat",
  "AWSMP - SA",
] as const

export type Task = BaseOpportunityTask & {
  subject: string
  status: typeof TASK_STATUS[number]
  priority: typeof TASK_PRIORITY[number]
  accountName: string
  activityDate: string
  createdDate: string
  timeSpentHrs: string
  lastModifiedDate: string
  description: string
  bdActivityType: string
  saActivity: string
  opportunityName: string
  opportunityWorkstreamId?: string
  relatedId?: string
  relatedType: string
  relatedName?: string
  type: string
  builderActivity?: boolean
}

const TASK_CLOSED_ARRAY: Task["status"][] = [
  "Completed",
  "Disqualified",
  "Unresponsive",
]

export const TASK_CLOSED_NAMES = new Set(TASK_CLOSED_ARRAY)
