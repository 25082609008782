import {
  Alert,
  BreadcrumbGroup,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Container,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react"
import opportunities from "api/opportunities"
import { Opportunity, OPPORTUNITY_STAGE } from "models/opportunity"
import MainLayout from "pmsa-polaris/components/MainLayout"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import usePromise from "pmsa-polaris/hooks/usePromise"
import qs from "qs"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { routeParams } from "routes"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import AcitivitesContainer from "./ActivitiesContainer"
import DetailsSection from "./DetailsSection"
import { update } from "lodash"

const { sfdcBaseUrl } = config

const DetailsPage: React.FC<{
  id: string
  opportunity?: Opportunity
  loading: boolean
  error: any
  opportunityQuickExtendHandler: Function
}> = ({ id, opportunity, loading, error, opportunityQuickExtendHandler }) => (
  <Container
    header={
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="m">
            <Button
              iconAlign="right"
              iconName="external"
              href={`${sfdcBaseUrl}/${id ?? ""}`}
              target="_blank"
            >
              View in SFDC
            </Button>
            <NavigationButton
              disabled={loading}
              href={`${routeParams.opportunitiesCreate()}?${qs.stringify(
                opportunity
              )}`}
            >
              New copy
            </NavigationButton>
            <ButtonDropdown
              items={[
                { text: "30 days", id: "ext30" },
                { text: "60 days", id: "ext60" },
                { text: "90 days", id: "ext90" },
              ]}
              onItemClick={(e) => opportunityQuickExtendHandler(e)}
            >
              Extend Close Date
            </ButtonDropdown>
            <NavigationButton
              iconAlign="right"
              iconName="edit"
              variant="primary"
              href={routeParams.opportunitiesEdit({ id })}
            >
              Edit
            </NavigationButton>
          </SpaceBetween>
        }
      >
        Opportunity Details
      </Header>
    }
  >
    <div
      style={{
        maxHeight: 330,
        height: 310,
        overflowY: "auto",
        overflowX: "clip",
      }}
    >
      <DetailsSection
        opportunity={opportunity}
        error={error}
        loading={loading}
      />
    </div>
  </Container>
)

const OpportunityDetails = () => {
  const { id: idParam } = useParams()
  const id = idParam!

  const [
    {
      loading: getOpportunityLoading,
      error: getOpportunityError,
      data: opportunity,
    },
    getOpportunity,
  ] = usePromise(opportunities.get)

  const [
    { loading: updateOpportunityLoading, error: updateOpportunityError },
    updateOpportunity,
  ] = usePromise(opportunities.update)

  useEffect(() => {
    getOpportunity(id)
  }, [getOpportunity, id])

  const setFlashMessages = useFlashbar()

  const opportunityQuickExtendHandler = async (
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    if (
      opportunity?.closeDate != undefined &&
      event.detail.id.startsWith("ext")
    ) {
      const extendableHygieneIssues = [
        "Asleep",
        "Forgotten",
        "Stale",
        "Neglected",
      ]

      if (
        opportunity.hygiene.length == 0 ||
        opportunity.hygiene.every((hygieneString) =>
          extendableHygieneIssues.some(
            (validHygiene) => hygieneString === validHygiene
          )
        )
      ) {
        const newDate = new Date(opportunity.closeDate)
        const newOpp: Opportunity = opportunity
        newDate.setDate(
          newDate.getDate() + parseInt(event.detail.id.substring(3))
        )
        switch (newOpp.stageName) {
          case OPPORTUNITY_STAGE[7]: // Completed
            newOpp.stageName = OPPORTUNITY_STAGE[1]
            break
          case OPPORTUNITY_STAGE[8]: // Closed Lost
            newOpp.stageName = OPPORTUNITY_STAGE[1]
            newOpp.closedLostCategory = ""
            newOpp.closedLostReasonNotes = ""
            break
        }
        newOpp.closeDate = newDate.toISOString().split("T")[0]

        await updateOpportunity(newOpp.id, newOpp)
          .then(() => {
            setFlashMessages([
              {
                type: "success",
                content: "Opportunity updated successfully!",
              },
            ])
          })
          .catch((error) => {
            getOpportunity(opportunity.id)
            setFlashMessages([
              {
                presist: false,
                type: "error",
                content:
                  "Opportunity update failed!" +
                  (error?.response?.data?.errorCode
                    ? ` (${error?.response?.data?.errorCode})`
                    : ""),
              },
            ])
          })
        getOpportunity(id)
      } else {
        setFlashMessages([
          {
            type: "error",
            content: "Opportunity has hygiene issues and cannot be updated!",
          },
        ])
      }
    }
  }

  return (
    <MainLayout>
      <BreadcrumbGroup
        items={[
          { text: "Opportunities", href: "/" },
          { text: id, href: "" },
        ]}
      />
      <SpaceBetween size="l">
        <DetailsPage
          id={id}
          error={getOpportunityError || updateOpportunityError}
          opportunity={opportunity}
          loading={getOpportunityLoading || updateOpportunityLoading}
          opportunityQuickExtendHandler={opportunityQuickExtendHandler}
        />

        <AcitivitesContainer id={id} opportunity={opportunity} />
      </SpaceBetween>
    </MainLayout>
  )
}

export default OpportunityDetails
