import profilesApi, { ProfileResponse } from "api/profiles"
import usePromise from "pmsa-polaris/hooks/usePromise"
import React, { useContext, useEffect, useMemo } from "react"

const DEFAULT_CONTEXT = {
  profile: undefined as ProfileResponse | undefined,
  profileLoading: false,
  profileError: null as any,
}

const ProfileContext = React.createContext(DEFAULT_CONTEXT)

export const useProfileContext = () => useContext(ProfileContext)

const ProfileContextProvider: React.FC = ({ children }) => {
  const [
    { loading: profileLoading, data: profile, error: profileError },
    getProfile,
  ] = usePromise(profilesApi.get)

  useEffect(() => {
    getProfile()
  }, [getProfile])

  const value = useMemo(
    () => ({
      profileLoading,
      profile,
      profileError,
    }),
    [profileLoading, profile, profileError]
  )

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export const useProfile = () => useContext(ProfileContext)

export default ProfileContextProvider
