export const DEFAULT_COLUMN_IDS = [
  "opportunityName",
  "partnerName",
  "stageName",
  "closeDate",
  "hygiene",
  "propensity",
]

export const VISIBLE_COLUMN_OPTIONS = [
  { id: "opportunityName", label: "Opportunity" },
  { id: "id", label: "Opportunity ID" },
  { id: "opportunityTitle", label: "Title" },
  { id: "workstreamId", label: "Workstream ID" },
  { id: "fiscalYear", label: "Fiscal Year" },
  { id: "partnerName", label: "Account Name (Partner)" },
  { id: "stageName", label: "Stage Name" },
  { id: "closeDate", label: "Close Date" },
  { id: "lastModifiedDate", label: "Last Modified Date" },
  { id: "createdDate", label: "Created Date" },
  { id: "closedLostCategory", label: "Closed Lost Category" },
  { id: "closedLostReasonNotes", label: "Closed Lost Reason Notes" },
  { id: "nextStep", label: "Next Step" },
  { id: "description", label: "Description" },
  { id: "tasks", label: "Tasks" },
  { id: "propensity", label: "Propensity" },
  { id: "hygiene", label: "Hygiene" },
]

export const SEARCHABLE_COLUMNS = [
  "name",
  "accountName",
  "id",
  "stageName",
  "closeDate",
  "workstreamId",
  "description",
  "title",
]
