import MainLayout from "pmsa-polaris/components/MainLayout"

import ToolsPanel from "./ToolsPanel"
import { WizardContent } from "./WizardContent"

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
const BuilderGoals = () => (
  <MainLayout contentType="wizard" tools={<ToolsPanel />}>
    <WizardContent />
  </MainLayout>
)

export default BuilderGoals
