import { SpaceBetween } from "@amzn/awsui-components-react"
import opportunities from "api/opportunities"
import { Opportunity } from "models/opportunity"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { useEffect } from "react"

import ActivitiesTable from "./ActivitiesTable"

type Props = {
  opportunity?: Opportunity
  id: string
}

const AcitivitesContainer: React.FC<Props> = ({ id, opportunity }) => {
  const [{ loading, error, data: tasks }, getOpportunityTasks] = usePromise(
    opportunities.getTasks
  )

  useEffect(() => {
    getOpportunityTasks(id)
  }, [getOpportunityTasks, id])

  return (
    <SpaceBetween size="l">
      <ActivitiesTable
        tasks={tasks}
        loading={loading}
        error={error}
        opportunity={opportunity}
      />
      <ActivitiesTable
        tasks={tasks}
        loading={loading}
        error={error}
        showClosed
      />
    </SpaceBetween>
  )
}

export default AcitivitesContainer
