import { Account } from "models/account"
import request from "pmsa-polaris/api/request"

export type AccountResponse = Readonly<Account>

const accountsApi = {
  search: (name: string) =>
    request.get<AccountResponse[]>(`/accounts/search/${name}`),
}

export default accountsApi
