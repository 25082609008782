import {
  OptionDefinition,
  OptionGroup,
} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import workstreamsApi from "api/workstreams"
import { mapValues } from "lodash"
import { GeoRegions, WorkstreamData, WorkstreamItem } from "models/workstream"
import usePromise from "pmsa-polaris/hooks/usePromise"
import React, { useContext, useEffect, useMemo } from "react"

type WorkstreamContext = {
  streamsById?: WorkstreamItemLookup
  streamsByName?: WorkstreamItemLookup
  taskOptions?: OptionGroup[]
  bdActivityOptions?: OptionGroup[]
  saActivityOptions?: OptionGroup[]
  regionOptionsByGeo?: Record<string, OptionDefinition[]>
  workstreamsLoading: boolean
  workstreamOptions?: OptionGroup[]
  workstreamCatalog?: Readonly<WorkstreamData>
  workstreamsError?: any
  geoOptions?: OptionDefinition[]
  geoRegions?: GeoRegions
}

const getLookupDefaultValues = () => ({
  tasks: {},
  workstreams: {},
})

const DEFAULT_VALUE = {
  workstreamsLoading: false,
}

const WorkstreamCatalogContext =
  React.createContext<WorkstreamContext>(DEFAULT_VALUE)

const TRACKING_KEYS = {
  Task: "tasks",
  Opportunity: "workstreams",
} as const

type CatalogData<T> = {
  tasks: T
  workstreams: T
}

type WorkstreamItemLookup = CatalogData<Record<string, WorkstreamItem>>

const WorkstreamCatalogContextProvider: React.FC = ({ children }) => {
  const [
    {
      loading: workstreamsLoading,
      data: workstreamCatalog,
      error: workstreamsError,
    },
    getWorkstreams,
  ] = usePromise(workstreamsApi.get)

  useEffect(() => {
    getWorkstreams()
  }, [getWorkstreams])

  const workstreamData = useMemo(() => {
    let streamsById: WorkstreamItemLookup | undefined = undefined
    let streamsByName: WorkstreamItemLookup | undefined = undefined

    const categoriesByTracking: {
      workstreams: Record<string, OptionGroup>
      tasks: Record<string, OptionGroup>
    } = getLookupDefaultValues()

    if (workstreamCatalog?.workstreams) {
      streamsById = getLookupDefaultValues()
      streamsByName = getLookupDefaultValues()
    }

    Object.entries(workstreamCatalog?.workstreams || {}).forEach(
      ([_, workstream]) => {
        workstream.streams.forEach((stream) => {
          if (stream.tracking) {
            const trackingKey =
              TRACKING_KEYS[stream.tracking as keyof typeof TRACKING_KEYS]

            streamsById![trackingKey][stream.id] = stream
            streamsByName![trackingKey][stream.name] = stream

            if (!categoriesByTracking[trackingKey][stream.category]) {
              categoriesByTracking[trackingKey][stream.category] = {
                label: stream.category,
                options: [],
              }
            }
            const options = categoriesByTracking[trackingKey][stream.category]
              .options as OptionDefinition[]

            options.push({
              label: stream.name,
              value: stream.name,
              description: stream.description,
            })
          }
        })
      }
    )

    const bdActivityOptions: OptionGroup[] = Object.entries(
      workstreamCatalog?.bdActivities ?? {}
    ).map(([label, { activities }]) => ({
      label,
      options: activities.map(({ name: value }) => ({ value })),
    }))

    const saActivityOptions: OptionGroup[] = Object.entries(
      workstreamCatalog?.saActivities ?? {}
    ).map(([label, { activities }]) => ({
      label,
      options: activities.map(({ name: value }) => ({ value })),
    }))

    const regionOptionsByGeo = mapValues(
      workstreamCatalog?.geoRegions || {},
      (regions: any) =>
        regions.map((region: any) => ({
          value: region,
        }))
    )

    const geoOptions: OptionDefinition[] = Object.keys(
      workstreamCatalog?.geoRegions || {}
    ).map((geo) => ({
      value: geo,
    }))

    return {
      streamsById,
      streamsByName,
      geoRegions: workstreamCatalog?.geoRegions,
      taskOptions: Object.values(categoriesByTracking.tasks),
      bdActivityOptions,
      saActivityOptions,
      geoOptions,
      regionOptionsByGeo,
      workstreamsLoading,
      workstreamCatalog,
      workstreamsError,
      workstreamOptions: Object.values(categoriesByTracking.workstreams),
    }
  }, [workstreamCatalog, workstreamsError, workstreamsLoading])

  return (
    <WorkstreamCatalogContext.Provider value={workstreamData}>
      {children}
    </WorkstreamCatalogContext.Provider>
  )
}

export const useWorkstreamCatalog = () => useContext(WorkstreamCatalogContext)

export default WorkstreamCatalogContextProvider
