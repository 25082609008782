import request from "pmsa-polaris/api/request"

export type ProfileData = {
  region?: string
  geo?: string
  sfdcUserDomain?: string
}

export type ProfileResponse = Readonly<ProfileData>

const profilesApi = {
  get: async () => request.get<ProfileResponse>("/profile"),

  update: (params: ProfileData) =>
    request.post<ProfileResponse>("/profile", params),
}

export default profilesApi
