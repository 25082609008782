import MainLayout from "pmsa-polaris/components/MainLayout"

import DetailsTable from "./TasksTable"

const TableView: React.FC = () => (
  <MainLayout contentType="table">
    <DetailsTable />
  </MainLayout>
)

export default TableView
