import { SelectProps } from "@amzn/awsui-components-react"
import { OptionsLoadItemsDetail } from "@amzn/awsui-components-react/polaris/internal/components/dropdown/interfaces"
import { DropdownStatusProps } from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status"
import { NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events"
import accountsApi from "api/accounts"
import {
  Props as FormFieldProps,
  SelectFormField,
} from "pmsa-polaris/components/FormFields"
import useDebounce from "pmsa-polaris/hooks/useDebounce"
import { useEffect, useState } from "react"

type Props = FormFieldProps & {
  initialOptions?: SelectProps.Options
}

const PartnerFormField: React.FC<Props> = ({
  name,
  initialOptions,
  ...props
}) => {
  const [options, setOptions] = useState<SelectProps.Options>([])
  const [status, setStatus] =
    useState<DropdownStatusProps.StatusType>("pending")

  useEffect(() => {
    if (initialOptions) {
      setOptions(initialOptions)
    }
  }, [initialOptions])

  const debouncedAutoComplete = useDebounce(accountsApi.search, 200)

  const handleLoadItems: NonCancelableEventHandler<OptionsLoadItemsDetail> = ({
    detail: { filteringText },
  }) => {
    if (filteringText && filteringText.length > 2) {
      const fetchAccounts = async () => {
        try {
          setStatus("loading")
          const accounts = await debouncedAutoComplete(filteringText)
          const options: SelectProps.Option[] = accounts.map((v) => ({
            value: v.id,
            label: v.name,
            description: v.partnerTier || undefined,
            labelTag: v.spmsId || undefined,
          }))
          setOptions(options)
          setStatus("finished")
        } catch (e) {
          console.error(e)
          setStatus("error")
        }
      }
      fetchAccounts()
    }
  }

  return (
    <SelectFormField
      name={name}
      filteringType="manual"
      onLoadItems={handleLoadItems}
      options={options}
      statusType={status}
      {...props}
    />
  )
}

export default PartnerFormField
