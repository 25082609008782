import "@amzn/awsui-global-styles/polaris.css"

//import "pmsa-polaris/styles.css"
import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation"
import Spinner from "@amzn/awsui-components-react/polaris/spinner"
import { CognitoUser } from "amazon-cognito-identity-js"
import Amplify, { Analytics, Auth } from "aws-amplify"
import TopNavigationBar from "components/TopNavigationBar"
import ProfileContextProvider from "context/ProfileContext"
import WorkstreamCatalogContextProvider from "context/WorkstreamCatalogContext"
import CreateBuilderGoals from "pages/CreateBuilderGoals"
import CreateUpdateOpportunity from "pages/CreateUpdateOpportunity"
import CreateUpdateTask from "pages/CreateUpdateTask"
import Home from "pages/Home"
import DetailsSection from "pages/OpportunityDetails"
import TaskDetails from "pages/TaskDetails"
import Tasks from "pages/Tasks"
import { onTokenExpired } from "pmsa-polaris/api/request"
import MainApp from "pmsa-polaris/components/MainApp"
import config from "pmsa-polaris/config"
import jwt from "pmsa-polaris/jwt"
import { useEffect, useState } from "react"
import { Route } from "react-router-dom"

import routes from "./routes"

onTokenExpired(async () => {
  const user = await Auth.currentAuthenticatedUser()
  jwt.token = user.getSignInUserSession()?.getIdToken().getJwtToken()!
})

const combineComponents = (...components: React.FC[]): React.FC => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: React.ComponentProps<React.FC>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}

const providers = [WorkstreamCatalogContextProvider, ProfileContextProvider]

const items: SideNavigationProps.Item[] = [
  {
    type: "section",
    text: "Opportunities (Outcomes)",
    items: [
      {
        type: "link",
        text: "Opportunities",
        href: `#${routes.home}`,
      },
      {
        type: "link",
        text: "Create Opportunity",
        href: `#${routes.opportunitiesCreate}`,
      },
    ],
  },
  {
    type: "section",
    text: "Tasks (Activities)",
    items: [
      {
        type: "link",
        text: " Tasks",
        href: `#${routes.tasksList}`,
      },
      { type: "link", text: "Create Task", href: `#${routes.tasksCreate}` },
    ],
  },
  {
    type: "section",
    text: "Builder Activities",
    items: [
      {
        type: "link",
        text: " Builder Activities",
        href: `#${routes.builderGoals}`,
      },
      {
        type: "link",
        text: " Create Builder Activity",
        href: `#${routes.builderGoalsCreate}`,
      },
    ],
  },
  { type: "divider" },
  {
    type: "link",
    text: "Explore your Partner Impact",
    href: "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/8b0e0cd7-f9b3-4b61-8f94-4b5abe89fb7f",
    external: true,
  },
]

const App = () => {
  const [error, setError] = useState(false)
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    document.body.classList.add("awsui-visual-refresh")

    const configureAmplify = async () => {
      const amplifyConfig = await import("./amplifyConfig")
      Auth.configure(amplifyConfig.default)

      const { pinpointId } = config

      const analyticsConfig = {
        AWSPinpoint: {
          // Amazon Pinpoint App Client ID
          appId: pinpointId,
          // Amazon service region
          region: "us-west-2",
          mandatorySignIn: false,
        },
      }

      Analytics.configure(analyticsConfig)
    }

    ;(async () => {
      try {
        await configureAmplify()

        try {
          const user: CognitoUser = await Auth.currentAuthenticatedUser()
          jwt.token = user.getSignInUserSession()?.getIdToken().getJwtToken()!
          setUser(user)
        } catch (loginError) {
          await Auth.federatedSignIn({ provider: "AmazonFederate" } as any)
        }
      } catch (error) {
        console.error(error)
        setError(true)
      }
      setLoading(false)
    })()
  }, [])

  return (
    (loading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (user && (
      <MainApp
        container={combineComponents(...providers)}
        serviceNavigationProps={{ items }}
        topNavigation={<TopNavigationBar />}
      >
        <Route path={routes.home} element={<Home />} />
        <Route path={routes.opportunitiesList} element={<Home />} />
        <Route
          path={routes.opportunitiesCreate}
          element={<CreateUpdateOpportunity />}
        />
        <Route
          path={routes.opportunitiesEdit}
          element={<CreateUpdateOpportunity />}
        />
        <Route
          path={routes.opportunitiesDetails}
          element={<DetailsSection />}
        />
        <Route path={routes.tasksList} element={<Tasks />} />
        <Route path={routes.tasksDetails} element={<TaskDetails />} />
        <Route path={routes.tasksCreate} element={<CreateUpdateTask />} />
        <Route path={routes.tasksEdit} element={<CreateUpdateTask />} />
        <Route
          path={routes.builderGoalsCreate}
          element={<CreateBuilderGoals />}
        />
        <Route path={routes.builderGoals} element={<Tasks />} />
      </MainApp>
    ))
  )
}

export default App
