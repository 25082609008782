export const DEFAULT_COLUMN_IDS = [
  "taskSubject",
  "opportunityName",
  "partnerName",
  "taskStatus",
  "dueDate",
]

export const DEFAULT_COLUMN_IDS_BUILDER = [
  "taskSubject",
  "opportunityName",
  "taskStatus",
  "dueDate",
  "builderActivity",
]

export const VISIBLE_COLUMN_OPTIONS = [
  { id: "taskSubject", label: "Task" },
  { id: "relatedName", label: "Opportunity" },
  { id: "partnerName", label: "Partner" },
  { id: "taskStatus", label: "Status" },
  { id: "dueDate", label: "Due Date" },
  { id: "saActivity", label: "SA Activity" },
  { id: "bdActivityType", label: "BD Activity Type" },
  { id: "createdDate", label: "Created Date" },
  { id: "description", label: "Description" },
  { id: "id", label: "Task ID" },
  { id: "lastModifiedDate", label: "Last Modified Date" },
  { id: "priority", label: "Priority" },
  { id: "timeSpentHrs", label: "Time Spent (Hrs)" },
  { id: "milestone", label: "Milestone" },
  { id: "opportunityWorkstreamId", label: "Opportunity Workstream" },
  { id: "builderActivity", label: "Builder Activity" },
]

export const SEARCHABLE_COLUMNS = [
  "id",
  "subject",
  "status",
  "accountName",
  "relatedName",
  "relatedId",
]
