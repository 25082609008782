import { Alert, Spinner } from "@amzn/awsui-components-react"
import { Opportunity } from "models/opportunity"
import moment from "moment"
import DateTime from "pmsa-polaris/components/DateTime"
import DetailsDisplay from "pmsa-polaris/components/DetailsDisplay"
import React from "react"

type Props = {
  opportunity?: Opportunity
  loading: boolean
  error: any
}

const DetailsSection: React.FC<Props> = ({ opportunity, loading, error }) => {
  const isLoading = loading || false

  moment().format("MM/DD/YYYY")

  return (
    (isLoading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (opportunity && (
      <DetailsDisplay
        details={[
          ["ID", opportunity.id],
          ["Name", opportunity.name],
          ["Account Name (Partner)", opportunity.accountName],
          ["Type", opportunity.workstreamName || "invalid"],
          ["Description", opportunity.description],
          ["Next Step", opportunity.nextStep],
          [
            "Hygiene Issue(s)",
            opportunity?.hygiene?.map((hygiene, index) => (
              <React.Fragment key={index}>
                <span>{hygiene}</span>
                <br />
              </React.Fragment>
            )) || "-",
          ],
          [
            "Hygiene Propensity",
            opportunity?.propensity?.map((propensity, index) => (
              <React.Fragment key={index}>
                <span>{propensity}</span>
                <br />
              </React.Fragment>
            )) || "-",
          ],
          ["Stage Name", opportunity.stageName],
          // eslint-disable-next-line react/jsx-key
          ["Close Date", moment(opportunity.closeDate).format("MM/DD/YYYY")],
          ["Owner", opportunity.owner],
          [
            "Last Modified Date",
            // eslint-disable-next-line react/jsx-key
            moment(opportunity.lastModifiedDate).format(
              moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
            ),
          ],
        ]}
      />
    )) ||
    (error && (
      <Alert visible={true} type="error" header="Failed to fetch details">
        Could not get details due to an error
      </Alert>
    )) ||
    null
  )
}

export default DetailsSection
