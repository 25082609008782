import { useCollection } from "@amzn/awsui-collection-hooks"
import {
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  CollectionPreferences,
  CollectionPreferencesProps,
  ColumnLayout,
  Header,
  Multiselect,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter,
} from "@amzn/awsui-components-react"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import tasks from "api/tasks"
import keyBy from "lodash/keyBy"
import { Task, TASK_STATUS } from "models/task"
import EmptyState from "pmsa-polaris/components/EmptyState"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import usePersistentState from "pmsa-polaris/hooks/usePersistentState"
import usePromise from "pmsa-polaris/hooks/usePromise"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import { paginationLabels } from "pmsa-polaris/utils"
import qs from "qs"
import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import routes, { routeParams } from "routes"

import {
  DEFAULT_COLUMN_IDS,
  DEFAULT_COLUMN_IDS_BUILDER,
  SEARCHABLE_COLUMNS,
} from "./columnNames"
import {
  COLUMN_DEFINITIONS,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from "./tableConfig"

const { sfdcBaseUrl } = config

const TasksTable = () => {
  const location = useLocation()
  const builderPage = location.pathname === "/buildergoals" || false
  const queryParams = useQueryString()
  const navigate = useNavigate()

  const { alias, status: queryStatus, ...searchParamsObject } = queryParams

  // task & builder activity status definitions
  const DEFAULT_STATUS = builderPage
    ? { label: "All Builder Activities", value: "builderAll" }
    : { label: "Open Tasks", value: "open" }

  const STATUS_OPTIONS: OptionDefinition[] = builderPage
    ? [
        DEFAULT_STATUS,
        { label: "Open Builder Activities", value: "builderOpen" },
        { label: "Completed Builder Activities", value: "builderComplete" },
      ]
    : [
        { label: "All Tasks", value: "all" },
        DEFAULT_STATUS,
        { label: "All Builder Tasks", value: "builderAll" },
        { label: "Open Builder Tasks", value: "builderOpen" },
        { label: "Completed Builder Tasks", value: "builderComplete" },
        { label: "Closed Tasks", value: "closed" },
        ...TASK_STATUS.map((v) => ({
          label: v,
          value: v.toLowerCase(),
        })),
      ]

  const STATUS_BY_VALUE = keyBy(STATUS_OPTIONS, "value")

  // get tasks
  const [
    { loading: getTaskLoading, error: getTaskError, data: getTasksData },
    getTasks,
  ] = usePromise(tasks.getAll)

  useEffect(() => {
    getTasks(alias)
  }, [getTasks, alias])

  const [errorMessage, setErrorMessage] = useState("Failed to fetch tasks")

  useEffect(() => {
    if (
      getTaskError &&
      getTaskError.response?.data?.errorCode == "USER_NOT_FOUND"
    ) {
      getTaskError.response?.data?.message &&
        setErrorMessage(getTaskError.response.data.message)
    }
  }, [getTaskError])

  // status filter
  const statusOption = STATUS_BY_VALUE[queryStatus] || DEFAULT_STATUS

  const statusChanged = useCallback(
    (option: OptionDefinition) => {
      navigate(`?${qs.stringify({ ...queryParams, status: option.value })}`, {
        replace: true,
      })
    },
    [navigate, queryParams]
  )

  const matchesStatus = (item: Readonly<Task>, status: string) => {
    if (status === "all") return true
    if (status === "builderAll" && item.builderActivity) return true
    if (
      status === "builderComplete" &&
      item.builderActivity &&
      item.status === "Completed"
    )
      return true
    if (
      status === "builderOpen" &&
      item.builderActivity &&
      !["Completed", "Disqualified"].includes(item.status)
    )
      return true
    if (status === "open")
      return !["Completed", "Disqualified"].includes(item.status)
    else if (status === "closed")
      return ["Completed", "Disqualified"].includes(item.status)
    else return status === item.status.toLowerCase()
  }

  const clearFilter = () => {
    actions.setFiltering("")
    statusChanged(DEFAULT_STATUS)
  }

  // partner filter
  const [partnerOptions, setPartnerOptions] = useState<OptionDefinition[]>([])
  const [selectedPartners, setSelectedPartners] = useState<OptionDefinition[]>(
    []
  )

  useEffect(() => {
    console.log("in partner filter useEffect")
    const tmp: OptionDefinition[] = []
    getTasksData?.forEach((item) => {
      if (
        tmp.find((element) => element.value == item.accountName) == undefined &&
        matchesStatus(item, statusOption.value!)
      ) {
        tmp.push({ label: item.accountName, value: item.accountName })
      }
    })
    setPartnerOptions(tmp)
  }, [getTasksData, statusOption.value])

  // table preferences
  const taskTablePreferences = builderPage
    ? "thunder.taskTablePreferencesBuilder"
    : "thunder.taskTablePreferences"

  const [preferences, setPreferences] =
    usePersistentState<CollectionPreferencesProps.Preferences>(
      taskTablePreferences,
      {
        pageSize: 10,
        visibleContent: builderPage
          ? DEFAULT_COLUMN_IDS_BUILDER
          : DEFAULT_COLUMN_IDS,
        wrapLines: false,
      }
    )

  // actions button handler
  const actionsHandler = (
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    if (event.detail.id == "edit") {
      selectedItems?.forEach((item) => {
        window.open(routeParams.tasksEdit({ id: item.id }))
      })
    }
  }

  // tasks collection
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(getTasksData || [], {
    filtering: {
      empty: (
        <EmptyState
          errorText={(getTaskError && errorMessage) || undefined}
          title="No tasks"
          subtitle="No tasks to display."
          action={
            builderPage ? (
              <NavigationButton
                variant="primary"
                href={routes.builderGoalsCreate}
              >
                Create Builder Activity
              </NavigationButton>
            ) : (
              <NavigationButton variant="primary" href={routes.tasksCreate}>
                Create Task
              </NavigationButton>
            )
          }
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={<Button onClick={() => clearFilter()}>Clear filter</Button>}
        />
      ),
      filteringFunction: (item, filteringText) => {
        if (!matchesStatus(item, statusOption.value!)) {
          return false
        }
        if (
          selectedPartners.length > 0 &&
          !selectedPartners.find((element) => element.value == item.accountName)
        ) {
          return false
        }
        const filteringTextLowerCase = filteringText.toLowerCase()
        return SEARCHABLE_COLUMNS.map((key) => item[key as keyof Task]).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().indexOf(filteringTextLowerCase) > -1
        )
      },
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "lastModifiedDate",
        },
        isDescending: true,
      },
    },
    selection: {},
  })

  const { selectedItems } = collectionProps

  // tasks table component
  return (
    <Table
      {...collectionProps}
      loading={getTaskLoading}
      loadingText="Loading tasks"
      selectionType="multi"
      stickyHeader={true}
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <TableHeader
          itemCount={getTasksData?.length}
          selectedItems={selectedItems}
          builderPage={builderPage}
          actionsHandler={actionsHandler}
        />
      }
      visibleColumns={preferences.visibleContent}
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      filter={
        <SpaceBetween size="l">
          <ColumnLayout columns={3}>
            <TextFilter
              {...filterProps}
              //countText={getMatchesCountText(filteredItemsCount!)}
              filteringAriaLabel="Filter tasks"
            />
            <Select
              options={STATUS_OPTIONS}
              selectedOption={statusOption}
              onChange={(event) => {
                statusChanged(event.detail.selectedOption)
              }}
            />
            <Multiselect
              options={partnerOptions}
              selectedOptions={selectedPartners}
              onChange={(event) =>
                setSelectedPartners([...event.detail.selectedOptions])
              }
              placeholder="Select a Partner"
              loadingText="Loading opportunities"
              statusType={
                getTaskLoading
                  ? "loading"
                  : partnerOptions.length == 0
                  ? "error"
                  : "finished"
              }
              errorText={
                partnerOptions.length == 0
                  ? "No Partners found"
                  : "There was an error"
              }
            />
          </ColumnLayout>
        </SpaceBetween>
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: "Page size",
            options: PAGE_SIZE_OPTIONS,
          }}
          wrapLinesPreference={{
            label: "Wrap lines",
            description: "Check to see all the text and wrap the lines",
          }}
          visibleContentPreference={{
            title: "Select visible columns",
            options: VISIBLE_CONTENT_OPTIONS,
          }}
        />
      }
    />
  )
}

const TableHeader: React.FC<{
  itemCount?: number
  selectedItems?: Readonly<Task[]>
  builderPage: boolean
  actionsHandler: Function
}> = ({ itemCount, selectedItems, builderPage, actionsHandler }) => {
  const selectedItem = selectedItems && selectedItems[0]

  const isOnlyOneSelected = selectedItems?.length === 1

  return (
    <Header
      variant="h2"
      counter={
        (!builderPage &&
          itemCount &&
          (selectedItems
            ? `(${selectedItems.length}/${itemCount})`
            : `(${itemCount})`)) ||
        ""
      }
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <ButtonDropdown
            items={[
              {
                id: "view-in-sfdc",
                text: "View in SFDC",
                disabled: selectedItems?.length != 1,
                href: `${sfdcBaseUrl}/${selectedItem?.id ?? ""}`,
                external: true,
              },
              {
                id: "new-copy",
                text: "New Copy",
                disabled: selectedItems?.length != 1,
                href: `${routes.tasksCreate}?${qs.stringify({
                  ...selectedItem,
                })}`,
              },
              {
                id: "edit",
                text: "Edit",
                disabled: selectedItems?.length == 0,
              },
            ]}
            onItemClick={(event) => actionsHandler(event)}
          >
            Actions
          </ButtonDropdown>
          {builderPage ? (
            <NavigationButton
              variant="primary"
              href={routes.builderGoalsCreate}
            >
              Create Builder Activity
            </NavigationButton>
          ) : (
            <NavigationButton variant="primary" href={routes.tasksCreate}>
              Create Task
            </NavigationButton>
          )}
        </SpaceBetween>
      }
    >
      {builderPage ? "Builder Activities" : "Tasks (Activities)"}
    </Header>
  )
}

export default TasksTable
