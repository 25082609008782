import {
  Button,
  Container,
  Form,
  Header,
  Link,
  SelectProps,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris"
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces"
import { BaseNavigationDetail } from "@amzn/awsui-components-react/polaris/internal/events"
import tasksApi, { TaskRequest } from "api/tasks"
import axios, { AxiosError } from "axios"
import { useWorkstreamCatalog } from "context/WorkstreamCatalogContext"
import { Formik, FormikHelpers, FormikProps } from "formik"
import { OmitWorkstreamId } from "models/shared"
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPE } from "models/task"
import moment from "moment"
import ErrorFocus from "pmsa-polaris/components/ErrorFocus"
import LoadingHeader from "pmsa-polaris/components/LoadingHeader"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import usePromise from "pmsa-polaris/hooks/usePromise"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { routeParams } from "routes"
import * as Yup from "yup"

import FieldContent from "./FieldContent"
import topics from "./tools-topics"

const { sfdcBaseUrl } = config

export type TaskFormValues = Omit<OmitWorkstreamId<TaskRequest>, "milestone">

export const DEFAULT_TASK: TaskFormValues = {
  title: "",
  timeSpentHrs: "",
  activityDate: moment().format("YYYY-MM-DD"),
  description: "",
  workstreamName: "",
  status: TASK_STATUS[0],
  priority: TASK_PRIORITY[0],
  bdActivityType: "",
  saActivity: "",
  relatedId: "",
  relatedType: "opportunity",
  type: TASK_TYPE[0],
  builderActivity: false,
}

const ERROR_TEXT = {
  serverError:
    "We can't process the request right now because of an issue with the server. Try again later.  If the problem persists, contact support",
  taskError: "There was an issue with creating task.",
  opportunityError: "There was an issue with creating opportunity.",
}

export const getValidaionSchema = (isTaskCreate: boolean) =>
  Yup.object().shape({
    workstreamName: Yup.string().required(
      "You must specify workstream function."
    ),
    title: Yup.string()
      .required("You must specify title.")
      .max(90, "Task subject can't be more than 90 characters.")
      .matches(/^[^\\]*$/, "Backslash is not allowed."),
    bdActivityType: Yup.string().required("You must specify bd activity type."),
    saActivity: Yup.string().required("You must specific a SA Activity"),
    timeSpentHrs: Yup.number()
      .typeError("You must specify a valid number")
      .positive("Time spent must be a positive number")
      .min(0, "Time spent must be greater than or equal to 0")
      .max(1000, "Time spent must be less than 1000")
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? NaN : value
      )
      .test(
        "decimaldigits",
        "You can specify a number up to 1 decimal place",
        (number) => (number ? Number.isInteger(number * 10) : true)
      ),
    activityDate: Yup.date()
      .required("You must specify due date")
      .typeError("You must specify date in the format YYYY/MM/DD")
      .min("2020/01/01", "You must specify date greater than 2020/01/01")
      .max(
        moment().add(1, "year"),
        "You must specify a task within 1 year from today"
      ),
    description: Yup.string()
      .max(32000, "Description can't be more than 32000 characters.")
      .matches(/^[^\\]*$/, "Backslash is not allowed.")
      .when("builderActivity", {
        is: true,
        then: Yup.string().required(
          "Comments are required for builder activities. Check out the wiki for more info: https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Reportingv2#HReportingBuilderGoals"
        ),
      }),
    status: Yup.string().required("You must specify status."),
    priority: Yup.string().required("You must specify priority."),
    ...(isTaskCreate && {
      relatedId: Yup.string().required("You must specify a related entity"),
    }),
    type: Yup.string().required("You must specify task type"),
  })
const FormContent: React.FC = () => {
  const { id } = useParams<{
    id: string
  }>()

  const queryParams = useQueryString()

  const {
    opportunity: opportunityQs = {},
    relatedName,
    ...searchParamsObject
  } = queryParams

  const opportunityData = useMemo(() => {
    if (opportunityQs) {
      const { workstreamName } = opportunityQs
      if (workstreamName) {
        return {
          workstreamName,
        }
      }
    }
    return undefined
  }, [opportunityQs])

  const [initialValues, setInitialValues] = useState<TaskFormValues>(() => {
    const defaultKeys = new Set(Object.keys(DEFAULT_TASK))
    Object.keys(searchParamsObject).forEach((key) => {
      if (!defaultKeys.has(key)) {
        delete searchParamsObject[key]
      }
    })

    const values = {
      ...DEFAULT_TASK,
      ...searchParamsObject,
    }

    return values
  })

  const navigate = useNavigate()
  const { setContext } = useAppContext()

  const { workstreamsLoading, streamsByName, geoOptions } =
    useWorkstreamCatalog()
  const [relatedToInitialOptions, setRelatedToInitialOptions] =
    useState<SelectProps.Options>(() => {
      if (relatedName && searchParamsObject.relatedId) {
        return [
          {
            label: relatedName,
            value: searchParamsObject.relatedId,
          },
        ]
      }
      return []
    })

  const [{ loading: updateTaskLoading, error: updateTaskError }, updateTask] =
    usePromise(tasksApi.update)

  const [
    { loading: getTaskLoading, error: getTaskError, data: existingTask },
    getTask,
  ] = usePromise(tasksApi.get)

  const [
    { loading: createTaskLoading, data: createdTask, error: createTaskError },
    createTask,
  ] = usePromise(tasksApi.create)

  const isLoading =
    getTaskLoading ||
    updateTaskLoading ||
    createTaskLoading ||
    workstreamsLoading

  const setFlashMessages = useFlashbar()

  const handleInfoClicked =
    (toolsTopic: keyof typeof topics) =>
    (e: CustomEvent<BaseNavigationDetail>) => {
      e.preventDefault()
      setContext({ toolsOpen: true, toolsTopic })
    }

  useEffect(() => {
    if (id) {
      const fetchTask = async () => {
        try {
          const task = await getTask(id)

          const values = {
            ...task,
            title: task.title ?? "",
            workstreamName: task.workstreamName ?? "",
            timeSpentHrs: task.timeSpentHrs ?? "",
            description: task.description ?? "",
            activityDate: task.activityDate ?? "",
          }

          setInitialValues(values)
          setRelatedToInitialOptions([
            {
              label: task.relatedName,
              value: task.relatedId,
            },
          ])
        } catch (error) {
          console.error(error)
        }
      }
      fetchTask()
    }
  }, [geoOptions, getTask, id])

  const handleSubmit = useCallback(
    async (
      { workstreamName, relatedId: opportunityId, ...task }: TaskFormValues,
      { setSubmitting }: FormikHelpers<TaskFormValues>
    ) => {
      try {
        const taskWorkstream = streamsByName?.tasks[workstreamName]

        const taskRequest: TaskRequest = {
          ...task,
          workstreamId: taskWorkstream?.id ?? "",
          relatedId: opportunityId!,
        }

        if (id) {
          const updatedTask = await updateTask(id, taskRequest)
          setFlashMessages([
            {
              content: "Task updated successfully",
              presist: true,
              type: "success",
            },
          ])
          navigate(
            routeParams.tasksDetails({
              id: updatedTask.id,
            })
          )
        } else {
          const newTask = await createTask(taskRequest)

          setFlashMessages([
            {
              content: "Task created successfully",
              presist: true,
              type: "success",
            },
          ])

          navigate(
            routeParams.tasksDetails({
              id: newTask.id,
            })
          )
        }
      } catch (error) {
        console.error(error)

        const err = error as AxiosError<{ statusCode: number; message: string }>
        if (
          err.response &&
          err.response.data &&
          err.response.data.statusCode == 403 &&
          err.response.data.message == "user does not own record"
        ) {
          sfdcBaseUrl

          const errmsg = `Only records that you own can be updated.  If you need to change the opportunity owner, navigate to ${sfdcBaseUrl}/${id}`
          setFlashMessages([
            {
              type: "error",
              content: errmsg,
            },
          ])
        } else {
          setFlashMessages([
            {
              type: "error",
              content: ERROR_TEXT.taskError,
            },
          ])
        }
      } finally {
        setSubmitting(false)
      }
    },
    [
      createTask,
      id,
      navigate,
      setFlashMessages,
      streamsByName?.tasks,
      updateTask,
    ]
  )

  const validationSchema = useMemo(() => getValidaionSchema(!id), [id])

  const pageTitle = id ? "Update Task" : "Create Task"

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        errors,
        values: task,
        isSubmitting,
        setFieldValue,
      }: FormikProps<TaskFormValues>) => (
        <form
          onSubmit={handleSubmit}
          className={isLoading ? "loading" : undefined}
        >
          <Form
            header={
              <LoadingHeader loading={isLoading}>{pageTitle}</LoadingHeader>
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <NavigationButton
                  variant="link"
                  href={
                    (id &&
                      routeParams.tasksDetails({
                        id: id,
                      })) ||
                    routeParams.home()
                  }
                  disabled={isSubmitting || isLoading}
                >
                  Cancel
                </NavigationButton>
                <Button
                  variant="primary"
                  formAction="submit"
                  disabled={isSubmitting || isLoading}
                  loading={isSubmitting || isLoading}
                >
                  {pageTitle}
                </Button>
              </SpaceBetween>
            }
            errorText={
              updateTaskError || createTaskError || getTaskError
                ? ERROR_TEXT.serverError
                : ""
            }
          >
            <ErrorFocus />
            <Container
              header={
                <Header
                  info={
                    <Link variant="info" onFollow={handleInfoClicked("main")}>
                      Info
                    </Link>
                  }
                >
                  Task (Activity)
                </Header>
              }
            >
              <FieldContent
                taskId={id}
                opportunityData={opportunityData}
                onInfoClicked={handleInfoClicked}
                relatedToInitialOptions={relatedToInitialOptions}
              />
            </Container>
          </Form>
        </form>
      )}
    </Formik>
  )
}

export default FormContent
