export type BUILDER_ACTIVITY_OUTCOME = {
  type: string
  comments: string
  validation?: string
}

export type LINKED = {
  type: string
  parent: string
  sfdcId?: string
  name?: string
}

export type BUILDER_ACTIVITY_RECORD = {
  subActivity: string
  activity: string
  linked: LINKED
  bdActivity: string
  saActivity: string
  outcome: BUILDER_ACTIVITY_OUTCOME
}

export const ACTIVITY_CATALOG: BUILDER_ACTIVITY_RECORD[] = [
  {
    subActivity: "Public Speaking - Partner Event",
    activity: "Public Speaking",
    bdActivity: "SA - TLE - Public Speaking (conferences)",
    saActivity: "",
    linked: {
      type: "Partner Opportunity",
      parent: "opportunity",
      sfdcId: "",
    },
    outcome: {
      type: "PE-13",
      comments: "speaking",
    },
  },
  {
    subActivity: "Public Speaking - AWS Event",
    activity: "Public Speaking",
    bdActivity: "SA - TLE - Public Speaking (conferences)",
    saActivity: "",
    linked: { type: "AWS Event Campaign", parent: "campaign", sfdcId: "" },
    outcome: {
      type: "PE-13",
      comments: "speaking",
    },
  },
  {
    subActivity: "Public Speaking - Non-AWS Event",
    activity: "Public Speaking",
    bdActivity: "SA - TLE - Public Speaking (conferences)",
    saActivity: "",
    linked: {
      type: "External Builder Campaign",
      parent: "campaign",
      sfdcId: "7014z000001kehZ",
      name: "WW-FY22-PMSA-Builder-External",
    },
    outcome: {
      type: "PE-13",

      comments: "speaking",
    },
  },
  {
    subActivity: "Blog - Non-Partner Related",
    activity: "External Content",
    saActivity: "",
    bdActivity: "SA - TLE - Blog",
    linked: {
      type: "External Builder Campaign",
      parent: "campaign",
      sfdcId: "7014z000001kehZ",
      name: "WW-FY22-PMSA-Builder-External",
    },
    outcome: {
      type: "PE-14",

      comments: "url",
    },
  },
  {
    subActivity: "Blog - Partner Related",
    activity: "External Content",
    saActivity: "",
    bdActivity: "SA - TLE - Blog",
    linked: {
      type: "Partner Opportunity",
      parent: "opportunity",
      sfdcId: "",
    },
    outcome: {
      type: "PE-14",

      comments: "url",
    },
  },
  {
    subActivity: "Whitepaper - Non-Partner Related",
    activity: "External Content",
    bdActivity: "SA - TLE - Whitepapers",
    saActivity: "",
    linked: {
      type: "External Builder Campaign",
      parent: "campaign",
      sfdcId: "7014z000001kehZ",
      name: "WW-FY22-PMSA-Builder-External",
    },
    outcome: {
      type: "PE-14",

      comments: "url",
    },
  },
  {
    subActivity: "Whitepaper - Partner Related",
    activity: "External Content",
    bdActivity: "SA - TLE - Whitepapers",
    saActivity: "",
    linked: {
      type: "Partner Opportunity",
      parent: "opportunity",
      sfdcId: "",
    },
    outcome: {
      type: "PE-14",

      comments: "url",
    },
  },
  {
    subActivity: "General Tech Content - Non-Partner Related",
    activity: "External Content",
    bdActivity: "SA - TLE - General Tech Content",
    saActivity: "",
    linked: {
      type: "External Builder Campaign",
      parent: "campaign",
      sfdcId: "7014z000001kehZ",
      name: "WW-FY22-PMSA-Builder-External",
    },
    outcome: {
      type: "PE-14",

      comments: "url",
    },
  },
  {
    subActivity: "General Tech Content - Partner Related",
    activity: "External Content",
    bdActivity: "SA - TLE - General Tech Content",
    saActivity: "",
    linked: {
      type: "Partner Opportunity",
      parent: "opportunity",
      sfdcId: "",
    },
    outcome: {
      type: "PE-14",

      comments: "url",
    },
  },
  {
    subActivity: "AWS Quickstart",
    activity: "External Content",
    bdActivity: "SA - TLE - General Tech Content",
    saActivity: "",
    linked: {
      type: "External Quickstart Campaign",
      parent: "campaign",
      sfdcId: "7014z000001kehj",
      name: "WW-FY22-PMSA-Builder-External-Quickstart",
    },
    outcome: {
      type: "PE-14",

      comments: "url",
    },
  },
  {
    subActivity: "Mechanism - Internal Improvement and Development",
    activity: "Internal Content",
    bdActivity: "PTR - TLE - Other",
    saActivity: "",
    linked: {
      type: "Internal Mechanism Campaign",
      parent: "campaign",
      sfdcId: "7014z000001oy8P",
      name: "WW-FY22-PMSA-Builder-Internal-Mechanisms",
    },
    outcome: {
      type: "PE-14",

      comments: "",
    },
  },
  {
    subActivity: "Mechanism - Workstream Contribution",
    activity: "Internal Content",
    bdActivity: "PTR - TLE - Other",
    saActivity: "",
    linked: {
      type: "Workstream Contribution Campaign",
      parent: "campaign",
      sfdcId: "7014z000001kehy",
      name: "WW-FY22-PMSA-Builder-Internal-Workstreams",
    },
    outcome: {
      type: "PE-14",

      comments: "url",
    },
  },
  {
    subActivity: "Programs - APN Immersion Day",
    activity: "Internal Content",
    bdActivity: "PTR - TLE - Other",
    saActivity: "",
    linked: {
      type: "APN Immersion Day Contribution Campaign",
      parent: "campaign",
      sfdcId: "7014z000001kei3",
      name: "WW-FY22-PMSA-Builder-Internal-Immersion Days",
    },
    outcome: {
      type: "ID-20",

      comments: "url",
    },
  },
  {
    subActivity: "Programs - Black Belt",
    activity: "Internal Content",
    bdActivity: "PTR - TLE - Other",
    saActivity: "",
    linked: {
      type: "Black Belt Contribution Campaign",
      parent: "campaign",
      sfdcId: "7014z000001kei8",
      name: "WW-FY22-PMSA-Builder-Internal-Black Belt",
    },
    outcome: {
      type: "BB-20",

      comments: "url",
    },
  },
  {
    subActivity: "Programs - AWS Ambassador",
    activity: "Internal Content",
    bdActivity: "PTR - TLE - Other",
    saActivity: "",
    linked: {
      type: "Ambassador Contribution Campaign",
      parent: "campaign",
      sfdcId: "7014z000001hec0",
      name: "WW-FY22-PMSA-Builder-Internal-AWS Ambassador",
    },
    outcome: {
      type: "AMB-20",

      comments: "url",
    },
  },
]
