import { BaseOpportunityTask } from "./shared"
import { Task } from "./task"

export const OPPORTUNITY_STAGE = [
  "Prospect",
  "In Progress",
  "Qualified",
  "On-hold",
  "Technical Validation",
  "Business Validation",
  "Committed",
  "Completed",
  "Closed Lost",
] as const

export type Opportunity = BaseOpportunityTask & {
  stageName: typeof OPPORTUNITY_STAGE[number]
  accountName: string
  closeDate: string
  nextStep: string
  lastModifiedDate: string
  createdDate: string
  description: string
  hygiene: string[]
  hygieneCount?: number
  propensity: string[]
  propensityCount?: number
  propensityDays?: number
  apnFocusedProgram: string
  closedLostCategory: string
  closedLostReasonNotes: string
  name: string
  fiscalYear?: number
  accountId: string
  tasks: Readonly<Task[]>
  owner: string
  solution: string
}
